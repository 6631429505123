import React from 'react'
import App from 'App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import axios from 'axios'
import './index.css'
import { CacheBuster } from '@piplup/cache-buster'

import {
	AuthContextProvider,
	NavContextProvider,
	StoreSettingsContextProvider,
	DashboardContextProvider
} from 'store'

if (
	window?.location?.host === 'localhost:3000' ||
	window?.location?.host === 'smartgo-qa.develocraftapp.com'
) {
	console.log(1)
	axios.defaults.baseURL = 'https://superstore.smartgo-testing.splitti.is/api/v2'
} else {
	console.log(2)
	console.log(window.location.host)
	axios.defaults.baseURL = `https://${window.location.host}/api/v2`
}

const queryClient = new QueryClient()

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
	<React.StrictMode>
		<CacheBuster />
		<AuthContextProvider>
			<QueryClientProvider client={queryClient}>
				<NavContextProvider>
					<StoreSettingsContextProvider>
						<DashboardContextProvider>
							<BrowserRouter>
								<App />
							</BrowserRouter>
						</DashboardContextProvider>
					</StoreSettingsContextProvider>
				</NavContextProvider>
			</QueryClientProvider>
		</AuthContextProvider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
