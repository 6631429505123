import { useTranslation } from 'libs'

import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import HistoryTable from 'components/HistoryTable'
import Header from 'components/Header'

const CouponHistoryPage = () => {
	const { t } = useTranslation()
	const params = useParams()

	const { data: couponHistory, isLoading } = useFetchData({
		name: 'fetchCouponHistory',
		endpoint: `/coupons/${params.id}/history/`
	})

	return (
		<>
			<Header title={t('couponHistory.title')} />
			<HistoryTable results={couponHistory?.data} isLoading={isLoading} />
		</>
	)
}

export default CouponHistoryPage
