import { format } from 'date-fns'
import { useTranslation } from 'libs'
import { enGB, is } from 'date-fns/locale'
import { useNavigate } from 'react-router-dom'

import EllipsisMenu from 'components/Ui/EllipsisMenu'
import Status from 'components/Ui/Status'

export type OpeningHourProps = {
	id: number
	date: string
	is_open: boolean
	reason: string
	is_holiday: string | null
	is_bookable: boolean
	counts_towards_duration: boolean
}

const OpeningHour = ({
	date,
	is_open,
	is_bookable,
	counts_towards_duration,
	is_holiday,
	reason
}: OpeningHourProps) => {
	const { t, i18n } = useTranslation()
	const { language } = i18n
	const navigate = useNavigate()
	const redirectToEditPage = () => navigate(`/opening-hours/${date}`)

	const ellipsisMenuOptions = [
		{
			label: t('openingHours.openingHour.ellipsisMenu.edit'),
			action: redirectToEditPage
		}
	]

	return (
		<article className="relative grid grid-cols-1	py-2.5 px-2 gap-2 text-xs font-semibold bg-white text-dustyGray border border-athensGray shadows-[0px_4px_4px_-2px_rgba(0,0,0,0.12)] rounded">
			<div className="flex items-center gap-4 [&>span]:flex-1 [&>span:last-of-type]:text-left">
				<span className="font-bold">Date: </span>
				<span>{date}</span>
			</div>
			<div className="flex items-center gap-4 [&>span]:flex-1 [&>span:last-of-type]:text-left">
				<span className="font-bold">Open: </span>
				<Status active={is_open} />
			</div>
			<div className="flex items-center gap-4 [&>span]:flex-1 [&>span:last-of-type]:text-left">
				<span className="font-bold">Bookable: </span>
				<Status active={is_bookable} />
			</div>
			<div className="flex items-center gap-4 [&>span]:flex-1 [&>span:last-of-type]:text-left">
				<span className="font-bold">Counts towards duration: </span>
				<span>
					<Status active={counts_towards_duration} />
				</span>
			</div>
			<div className="flex items-center gap-4 [&>span]:flex-1 [&>span:last-of-type]:text-left">
				<span className="font-bold">Weekday: </span>
				<span>{format(new Date(date), 'EEEE', { locale: language === 'is' ? is : enGB })}</span>
			</div>
			<div className="flex items-center gap-4 [&>span]:flex-1 [&>span:last-of-type]:text-left">
				<span className="font-bold">Holiday: </span>
				<span>{is_holiday}</span>
			</div>
			<div className="flex items-center gap-4 [&>span]:flex-1 [&>span:last-of-type]:text-left">
				<span className="font-bold">Reason: </span>
				<span>{reason}</span>
			</div>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</article>
	)
}

export default OpeningHour
