import styled from 'styled-components'
import { theme } from 'twin.macro'
import { WithClassName } from 'types/general'

type ITableImage = WithClassName<{
	src: string
	alt?: string
	count?: number
}>

const TableImage = ({ src, count, alt, className }: ITableImage) => {
	return (
		<Figure className={className}>
			<img src={src ?? 'https://via.placeholder.com/75x75'} alt={alt} />

			{count ? <Count>{count}</Count> : null}
		</Figure>
	)
}

const Figure = styled.figure`
	position: relative;
	flex: 0 0 50px;
	width: 50px;
	height: 50px;

	@media (min-width: 375px) {
		flex: 0 0 75px;
		width: 75px;
		height: 75px;
	}

	@media (min-width: ${theme`screens.md`}) {
		flex: 0 0 50px;
		width: 50px;
		height: 50px;
	}

	img {
		max-height: 100%;
		margin: auto;
	}
`

const Count = styled.span`
	display: block;
	min-width: 15px;
	height: 16px;
	background-color: #3c67ff;
	color: #fff;
	position: absolute;
	text-align: center;
	padding: 0px 4px;
	border-radius: 4px;
	top: -2px;
	right: -8px;

	@media (min-width: ${theme`screens.md`}) {
		display: none;
	}
`

export default TableImage
