import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { WithClassName } from 'types/general'
import { cn } from 'utils/style'

type CtaLinkProps = WithClassName<{
	text: string
	to: string
	icon?: boolean
}>

const CtaLink = ({ text, to, className, icon = true }: CtaLinkProps) => {
	return (
		<NavLink
			to={to}
			className={cn(
				'text-white bg-dodgerBlue rounded-md p-2.5 items-center flex justify-center relative min-w-[136px] font-black text-sm gap-2.5 whitespace-nowrap',
				className
			)}>
			<span>{text}</span>{' '}
			{icon && <FontAwesomeIcon icon={faPlus} className="absolute right-2.5 md:static" />}
		</NavLink>
	)
}

export default CtaLink
