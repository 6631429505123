import { ITab } from './types'
import Tab from './Tab'

export type TabsProps = {
	tabs: ITab[]
	activeTab: string
	changeTab?: (data: any) => void
}

const FormTabs = ({ tabs, activeTab, changeTab }: TabsProps) => {
	return (
		<div className="flex overflow-x-auto border border-seashell rounded-t-lg [&>*]:flex-1 [&>*:nth-of-type(n+2)]:border-l [&>*:nth-of-type(n+2)]:border-l-seashell">
			{tabs.map((tab: any, index: number) => (
				<Tab
					key={index}
					tab={tab}
					handleClick={() => changeTab && changeTab(tab.value)}
					isActive={activeTab === tab.value}
				/>
			))}
		</div>
	)
}

export default FormTabs
