import { PropsWithChildren } from 'react'
import { Styles } from 'react-modal'

import { Modal } from 'components/Ui'
import { Button } from 'components/Ui'
import { t } from 'i18next'

import { customModalStyles } from 'styles/general'

type IConfirmModal = PropsWithChildren<{
	isOpened: boolean
	title: string
	closeModal: () => void
	confirm: () => void
}>

const customStyles: Styles = {
	...customModalStyles,
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: '350px',
		backgroundColor: '#fff',
		width: '100%',
		padding: '0',
		border: 'none'
	}
}

const ConfirmModal = ({ isOpened, closeModal, title, confirm, children }: IConfirmModal) => {
	return (
		<Modal isOpened={isOpened} closeModal={closeModal} title={title} customStyles={customStyles}>
			{children && <div className="px-4 pb-4 text-sm text-scorpion">{children}</div>}
			<div className="flex justify-end gap-4 bg-mineShaft py-4 px-8">
				<Button text={t('confirmModal.cancel')} styleType="cancel" onClick={closeModal} />
				<Button text={t('confirmModal.confirm')} styleType="primary" onClick={confirm} />
			</div>
		</Modal>
	)
}

export default ConfirmModal
