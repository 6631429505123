import { Link } from 'react-router-dom'
import { useTranslation } from 'libs'

import { EllipsisText } from 'components/Ui'
import CardWrapper from 'components/Card/Wrapper'

import type { DashboardListProps } from '../types'

export const DashboardList = ({ title, list = [], emptyText }: DashboardListProps) => {
	const { t } = useTranslation()

	return (
		<CardWrapper className="p-4">
			<h3 className="font-semibold text-base text-emperor mb-4">{title}</h3>
			<div className="grid lg:grid-cols-2 gap-x-8">
				{list.length ? (
					list.map((listItem) => (
						<div className="flex justify-between items-center py-1" key={listItem.id}>
							<EllipsisText>
								<span className="text-sm text-dustyGray">{listItem.name}</span>
							</EllipsisText>
							<Link to={`/products/${listItem.id}`} className="text-sm text-blueRibbon">
								{t('dashboard.tiles.anchor')}
							</Link>
						</div>
					))
				) : (
					<p>{emptyText}</p>
				)}
			</div>
		</CardWrapper>
	)
}
