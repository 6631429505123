import { useTranslation } from 'libs'

import Header from 'components/Header'
import { DashboardSummaries, DashboardBoothsList, DashboardLists } from './sections'

const DashboardPage = () => {
	const { t } = useTranslation()

	return (
		<>
			<Header title={t('dashboard.title')} />
			<div className="flex flex-col gap-3 my-8 px-6 lg:p-0 lg:gap-8">
				<DashboardSummaries />
				<DashboardBoothsList />
				<DashboardLists />
			</div>
		</>
	)
}

export default DashboardPage
