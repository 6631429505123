import { useTranslation } from 'libs'
import { NavLink, useNavigate } from 'react-router-dom'

import { Currency } from 'components/Ui'
import EllipsisMenu from 'components/Ui/EllipsisMenu'
import { Rental as RentalType } from './types'
import Status from 'components/Ui/Status'

export type RentalProps = RentalType & {
	commission: string
	price: {
		currency: string
		amount: string
	}
	checked: boolean
	booth_type_name: {
		id: number
		name: string
	}
	onCheckboxChange: ({ id, checked }: { id: number; checked: boolean }) => void
	openDeleteModal: (id: number) => void
}

const Rental = ({
	id,
	duration,
	commission,
	price,
	booth_type_name,
	is_active,
	openDeleteModal
}: RentalProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	const ellipsisMenuOptions = [
		{
			label: t('rentals.rental.ellipsisMenu.edit'),
			action: () => navigate(`/rentals/${id}`)
		},
		{
			label: t('rentals.rental.ellipsisMenu.delete'),
			action: () => openDeleteModal(id)
		}
	]

	return (
		<article className="relative grid gap-2 text-xs font-semibold text-dustyGray p-3 bg-white border border-athensGray shadow-[0px_4px_4px_-2px_rgba(0,0,0,0.12)] rounded text-left">
			<span>
				{booth_type_name?.name ? (
					<>
						<span className="font-bold">{t('rentals.rental.rental')}</span>{' '}
						<NavLink to={`/rentals/${id}`}>
							{booth_type_name?.name ? `${booth_type_name?.name} - ` : ''} {duration}
						</NavLink>
					</>
				) : (
					''
				)}
			</span>
			<span>
				{booth_type_name?.name ? (
					<>
						<span className="font-bold">{t('rentals.rental.boothType')}</span>{' '}
						{booth_type_name?.name}
					</>
				) : (
					''
				)}
			</span>
			<span>
				{duration ? (
					<>
						<span className="font-bold">{t('rentals.rental.duration')}</span> {duration}
					</>
				) : (
					''
				)}
			</span>
			<span>
				{commission ? (
					<>
						<span className="font-bold">{t('rentals.rental.commission')}</span> {commission}
					</>
				) : (
					''
				)}
			</span>
			<span>
				{price ? (
					<>
						<span className="font-bold">{t('rentals.rental.price')}</span>{' '}
						<Currency price={price} />
					</>
				) : (
					''
				)}
			</span>
			<span>
				<span className="text-doveGray">{t('rentals.tableHeaders.isActive')}: </span>
				<Status active={is_active} />
			</span>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</article>
	)
}

export default Rental
