import { forwardRef } from 'react'

import { Wrapper, Header, Label, Switcher, StyledInput, Slider, Description } from './styles'
import { WithClassName } from 'types/general'

type ISwitch = WithClassName<{
	name: string
	label: string
	description?: string
	checked?: boolean
	disabled?: boolean
	onChange?: (e: any) => void
}>

const Switch = forwardRef(
	({ name, label, description, className, disabled = false, onChange, ...rest }: ISwitch, ref) => {
		return (
			<Wrapper className={className}>
				<Header>
					<Label>{label}</Label>
					<Switcher>
						<StyledInput
							type="checkbox"
							name={name}
							ref={ref}
							disabled={disabled}
							onChange={onChange}
							{...rest}
						/>
						<Slider />
					</Switcher>
				</Header>
				<Description>{description}</Description>
			</Wrapper>
		)
	}
)

export default Switch
