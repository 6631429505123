import { forwardRef, Ref, ChangeEvent } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldError, Controller } from 'react-hook-form'
import flags from 'react-phone-number-input/flags'
import Label from 'components/Ui/Label'
import 'react-phone-number-input/style.css'
import PhoneInput, { Country, isValidPhoneNumber } from 'react-phone-number-input'
import {
	// CountryCode,
	// MetadataJson
	E164Number
} from 'libphonenumber-js/core'
import { WithClassName } from 'types/general'

type InputProps = WithClassName<{
	id: string
	label?: string
	name: string
	type?: string
	icon?: any
	error?: FieldError | undefined
	placeholder?: string
	disabled?: boolean
	ref?: Ref<HTMLInputElement>
	onChange?: (e: ChangeEvent) => void
	step?: string
	control?: any
	defaultCountry?: Country
}>

const PhoneNumberInput = forwardRef(
	(
		{
			id,
			label,
			name,
			icon,
			placeholder,
			onChange,
			disabled,
			className,
			type = 'text',
			error,
			step,
			control,
			defaultCountry
		}: InputProps,
		ref
	) => {
		const onChange2 = (value?: E164Number | undefined): void => {
			if (onChange) {
				const event = {
					target: {
						value: value as string
					}
				}
				onChange(event as any)
			}
		}
		return (
			<Group className={className}>
				{label && <Label htmlFor={id}>{label}</Label>}
				<FieldWrapper>
					{icon && (
						<>
							{typeof icon === 'string' ? (
								<IconWrapper>{icon}</IconWrapper>
							) : (
								<IconWrapper>
									<Icon icon={icon} />
								</IconWrapper>
							)}
						</>
					)}

					<Controller
						name={name}
						rules={{
							validate: (value) => isValidPhoneNumber(value)
						}}
						control={control}
						render={({ field: { onChange, value } }) => (
							<PhoneInput
								type={type}
								id={id}
								placeholder={placeholder}
								onChange={onChange}
								value={value}
								flags={flags}
								// ref={ref}
								withIcon={icon}
								disabled={disabled}
								step={step}
								defaultCountry={defaultCountry}
								countryCallingCodeEditable={false}
							/>
						)}
					/>
				</FieldWrapper>
				{error && <Error>{error.message}</Error>}
			</Group>
		)
	}
)

const Group = tw.div`
	flex
	flex-col
	gap-y-2.5
`

const FieldWrapper = styled.div`
	display: flex;
	align-items: center;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	gap: 10px;
	position: relative;
`

const StyledInput = styled.input<{ withIcon: boolean }>`
	font-size: 12px;
	border-radius: 8px;
	width: 100%;
	padding: ${({ withIcon }) => (withIcon ? '10px 10px 10px 45px' : '10px')};

	&:focus {
		outline: none;
		box-shadow: 0 0 5px #465cff;
	}
`

export const IconWrapper = styled.div`
	position: absolute;
	left: 0;
	width: 39px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px 0 0 8px;
	color: #949494;
	top: 0;
	bottom: 0;
	font-size: 14px;
	background-color: #e3e3e3;
`

const Icon = styled(FontAwesomeIcon)``

const Error = styled.span`
	color: red;
	font-size: 10px;
	font-weight: 700;
`

export default PhoneNumberInput
