import { useContext } from 'react'
import { useTranslation } from 'libs'
import {
	BanknotesIcon,
	Squares2X2Icon,
	InboxIcon,
	ShoppingBagIcon
} from '@heroicons/react/24/outline'

import Summary from 'components/Card/Summary'
import { Currency } from 'components/Ui'
import { DashboardContext } from 'store'

export const DashboardSummaries = () => {
	const dashboardCtx = useContext(DashboardContext)

	const { t } = useTranslation()

	return (
		<div className="grid gap-3 grid-cols-2 md:grid-cols-4 md:gap-6">
			<Summary
				title={t('dashboard.cards.card1.title')}
				content={
					<Currency
						price={{
							amount: dashboardCtx?.dashboard?.data?.balance as string,
							currency: dashboardCtx?.dashboard?.data?.currency as string
						}}
					/>
				}
				summaryCounter={
					<Currency
						price={{
							amount: dashboardCtx?.dashboard?.data?.profit_last_week as string,
							currency: dashboardCtx?.dashboard?.data?.currency as string
						}}
					/>
				}
				summaryText={t('dashboard.cards.card1.text')}
				icon={<BanknotesIcon />}
				iconBgColor="bg-zahan"
				iconColor="text-apple"
			/>

			<Summary
				title={t('dashboard.cards.card2.title')}
				content={
					<>
						<div>{dashboardCtx?.dashboard?.data?.products_sold}</div>
					</>
				}
				summaryCounter={new Intl.NumberFormat().format(
					parseInt(dashboardCtx?.dashboard?.data?.sales_past_week.quantity as string)
				)}
				summaryText={t('dashboard.cards.card2.text')}
				icon={<Squares2X2Icon />}
			/>

			<Summary
				title={t('dashboard.cards.card3.title')}
				content={dashboardCtx?.dashboard?.data?.active_booths_count}
				icon={<InboxIcon />}
				iconBgColor="bg-colonialWhite"
				iconColor="text-sun"
			/>

			<Summary
				title={t('dashboard.cards.card4.title')}
				content={dashboardCtx?.dashboard?.data?.products_count}
				summaryCounter={
					<Currency
						price={{
							amount: dashboardCtx?.dashboard?.data?.products_total_value.amount as string,
							currency: dashboardCtx?.dashboard?.data?.currency as string
						}}
					/>
				}
				summaryText={t('dashboard.cards.card4.text')}
				icon={<ShoppingBagIcon />}
				iconBgColor="bg-azalea"
				iconColor="text-cardinal"
			/>
		</div>
	)
}
