import { cn } from 'utils/style'
import { ITab } from './types'

type TabProps = {
	tab: ITab
	isActive: boolean
	handleClick: (data: any) => void
}

const Tab = ({ tab, isActive, handleClick }: TabProps) => {
	return (
		<button
			type="button"
			className={cn(
				'bg-white text-xs p-1 font-semibold text-grayChateau sm:text-sm p-2.5',
				isActive ? 'text-royalBlue border-b-2 border-b-royalBlue bg-athensGray2' : ''
			)}
			onClick={handleClick}>
			{tab.label}
		</button>
	)
}

export default Tab
