import { useState, useContext } from 'react'
import { useTranslation } from 'libs'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { Column } from '@table-library/react-table-library/compact'

import {
	useFetchData,
	usePagination,
	useModal,
	useAxiosPrivate,
	useSearch,
	useToast,
	useDevice,
	useTableSelect
} from 'hooks'
import Tag from 'components/Tag'
import EmptyTable from 'components/Ui/Table/Empty'
import Filters from 'components/Filters'
import { IActionFilter } from 'interfaces/filters.interface'
import DeleteConfirmModal from 'components/Modals/deleteConfirm'
import ConfirmModal from 'components/Modals/confirm'
import { IOption } from 'interfaces/select.interface'
import { defaultConfig } from 'config/default'
import { downloadAs } from 'utils/downloadFile'
import FormatModal from 'components/Modals/format'
import { customTableStyles } from 'styles/general'
import ContextMenu from 'components/Ui/ContextMenu'
import { AuthContext } from 'store/AuthContext'
import { theme } from 'twin.macro'
import Table2 from 'components/Ui/Table2'
import Header from 'components/Header'
import CtaLink from 'components/Ui/CtaLink'
import FiltersWrapper from 'components/FiltersWrapper'
import SearchInput from 'components/Ui/SearchInput'
import Table from 'components/Ui/Table'

const TagsPage = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { handlePagination } = usePagination(setQuery)
	const axiosPrivate = useAxiosPrivate()
	const [checkedTags, setCheckedTags] = useState<number[]>([])
	const [idToBeRemoves, setIdToBeRemoved] = useState<number | null>(null)
	const { handleSearch } = useSearch({ name: 'search', cb: setQuery })
	const queryClient = useQueryClient()
	const [chosenExportFormat, setChosenExportFormat] = useState<number | string | null>(null)
	const { isMobileOrTablet } = useDevice()
	const [tagsData, setTagsData] = useState({ nodes: [] })
	const { select } = useTableSelect(tagsData, onSelectChange)
	const navigate = useNavigate()
	const { auth } = useContext(AuthContext)

	const {
		isOpen: isSingleDeleteModalOpen,
		openModal: openSingleDeleteModal,
		closeModal: closeSingleDeleteModal
	} = useModal()
	const {
		isOpen: isExportModalOpen,
		openModal: openExportModal,
		closeModal: closeExportModal
	} = useModal()
	const {
		isOpen: isBulkDeleteModalOpen,
		openModal: openBulkDeleteModal,
		closeModal: closeBulkDeleteModal
	} = useModal()
	const {
		isOpen: isFormatModalOpen,
		openModal: openFormatModal,
		closeModal: closeFormatModal
	} = useModal()

	const { data: tags, isFetching: loadingTags } = useFetchData({
		name: 'fetchTags',
		endpoint: '/tags/',
		query,
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setTagsData({ nodes: results })
			},
			enabled: !!auth.accessToken
		}
	})

	const deleteSuccessMsg = useToast({
		type: 'success',
		message: t('tags.modal.delete.success')
	})
	const deleteFailMsg = useToast({
		type: 'error',
		message: t('tags.modal.delete.error')
	})

	const bulkDeleteSuccessMsg = useToast({
		type: 'success',
		message: t('tags.modal.delete.success')
	})
	const bulkDeleteFailMsg = useToast({
		type: 'error',
		message: t('tags.modal.delete.error')
	})

	const exportSuccess = useToast({
		type: 'success',
		message: t('tags.modal.export.success')
	})
	const exportFail = useToast({
		type: 'error',
		message: t('tags.modal.export.error')
	})

	const { mutate: tagDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.delete(`/tags/${idToBeRemoves}/`)
		},
		{
			onSuccess: () => {
				deleteSuccessMsg()
				queryClient.invalidateQueries('fetchTags')
			},
			onError: () => {
				deleteFailMsg()
			},
			onSettled: () => {
				closeSingleDeleteModal()
			}
		}
	)

	const { mutate: bulkDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.post('/tags/bulk_delete/', { ids: checkedTags })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchTags')
				bulkDeleteSuccessMsg()
			},
			onError: () => {
				bulkDeleteFailMsg()
			},
			onSettled: () => {
				setCheckedTags([])
				closeBulkDeleteModal()
			}
		}
	)

	const handleFormatChange = (data: IOption) => {
		data?.value?.toString() ? setChosenExportFormat(data.value) : setChosenExportFormat(null)
	}

	const { mutate: exportBulkMutation, isLoading: isExportLoading } = useMutation(
		() => {
			return axiosPrivate.post(
				'/tags/export/',
				{ format: chosenExportFormat },
				{ responseType: 'arraybuffer' }
			)
		},
		{
			onSuccess: (res) => {
				const format = defaultConfig.exportFormatOptions
					.find((format) => format.value === chosenExportFormat)
					?.label.toLowerCase()

				if (format) {
					downloadAs({ file: res.data, name: 'tags-export', format })
				}

				exportSuccess()
			},
			onError: () => {
				exportFail()
			},
			onSettled: () => {
				setCheckedTags([])
				closeFormatModal()
			}
		}
	)

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value
		switch (action) {
			case 'delete':
				openBulkDeleteModal()
				break
			case 'export':
				openExportModal()
				break
		}
	}

	const handleDelete = (id: number) => {
		setIdToBeRemoved(id)
		openSingleDeleteModal()
	}

	const handleExportModal = () => {
		closeExportModal()
		openFormatModal()
	}

	const tableHeaders = [
		{
			id: 'tag',
			label: t('tags.tableHeaders.tag')
		},
		{
			id: 'slug',
			label: t('tags.tableHeaders.slug')
		}
	]

	const actionFilters = [
		{
			id: 'actions',
			label: t('tags.actions.label'),
			placeholder: t('tags.actions.placeholder'),
			options: [
				{
					value: 'export',
					label: t('tags.actions.options.export')
				},
				{
					value: 'delete',
					label: t('tags.actions.options.delete')
				}
			]
		}
	]

	const handleEdit = (id: number) => {
		navigate(`/tags/${id}`)
	}

	const handleDeleteModalOpen = (id: number) => {
		handleDelete(id)
	}

	const contextMenuOptions = [
		{
			label: t('tags.ellipsisMenu.edit'),
			action: handleEdit
		},
		{
			label: t('tags.ellipsisMenu.delete'),
			action: handleDeleteModalOpen
		}
	]
	const columns: Column[] = [
		{
			label: t('tags.tableHeaders.tag'),
			renderCell: (item: any) => (
				<NavLink style={{ color: theme`colors.blueRibbon` }} to={`/tags/${item.id}`}>
					{item.name}
				</NavLink>
			),
			select: true,
			pinLeft: true
		},
		{
			label: t('tags.tableHeaders.slug'),
			renderCell: (item: any) => item.slug
		},
		{
			label: '',
			renderCell: (item: any) => (
				<div className="relative">
					<ContextMenu dataId={item.id} options={contextMenuOptions} />
				</div>
			)
		}
	]

	function onSelectChange(action: any, state: any) {
		setCheckedTags(state.ids)
	}

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
		${customTableStyles.Table};
			--data-table-library_grid-template-columns:  40px repeat(2, 1fr) 40px;
		`
	}

	return (
		<>
			<div className="flex justify-between w-full">
				<Header className="w-full" title={t('tags.title')} handleSearch={handleSearch} />
				<div className="hidden lg:w-auto lg:p-0 lg:ml-auto lg:block">
					<CtaLink className="w-full md:w-auto" to="/tags/add" text={t('tags.cta')} />
				</div>
			</div>
			<FiltersWrapper className="justify-between py-3 px-6 lg:px-0">
				<div className="flex gap-5">
					<SearchInput
						className="hidden lg:flex"
						label={t('tags.filters.search.label')}
						placeholder={t('tags.filters.search.placeholder')}
						onChange={handleSearch}
					/>
					<Filters
						className="hidden lg:flex"
						filters={actionFilters}
						disableActions={!checkedTags.length}
						handleFilter={handleActionFilter}
					/>
				</div>
				<div className="flex justify-end w-full lg:hidden">
					<CtaLink className="w-full md:w-auto" to="/tags/add" text={t('tags.cta')} />
				</div>
			</FiltersWrapper>
			{tagsData.nodes != null && Array.isArray(tagsData.nodes) && tagsData.nodes.length ? (
				<>
					{isMobileOrTablet ? (
						<Table
							isLoading={loadingTags}
							prev={tags?.data?.previous}
							next={tags?.data?.next}
							headers={tableHeaders}
							onClick={handlePagination}>
							{tags?.data?.results.map((tag: any) => (
								<Tag key={tag.id} {...tag} openDeleteModal={handleDelete} />
							))}
						</Table>
					) : (
						<Table2
							className="h-[65vh] md:h-[calc(100vh-59px-164px)] lg:h-[calc(100vh-2rem-27px-90px-125px)] 2xl:h-[calc(100vh-2rem-27px-215px)]"
							columns={columns}
							data={tagsData}
							isLoading={loadingTags}
							select={select}
							prev={tags?.data?.previous}
							next={tags?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}

					{isExportModalOpen && (
						<ConfirmModal
							title={t('tags.modal.export.title')}
							isOpened={isExportModalOpen}
							closeModal={closeExportModal}
							confirm={handleExportModal}>
							<p>{t('tags.modal.export.text')}</p>
						</ConfirmModal>
					)}
					{isSingleDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('tags.modal.delete.title')}
							isOpened={isSingleDeleteModalOpen}
							closeModal={closeSingleDeleteModal}
							confirm={tagDeleteMutation}>
							<p>{t('tags.modal.delete.text')}</p>
						</DeleteConfirmModal>
					)}
					{isBulkDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('tags.modal.bulkDelete.title')}
							isOpened={isBulkDeleteModalOpen}
							closeModal={closeBulkDeleteModal}
							confirm={bulkDeleteMutation}>
							<p>{t('tags.modal.bulkDelete.text', { count: checkedTags.length })}</p>
						</DeleteConfirmModal>
					)}
					{isFormatModalOpen && (
						<FormatModal
							disableButton={chosenExportFormat === null}
							isOpened={isFormatModalOpen}
							closeModal={closeFormatModal}
							confirm={exportBulkMutation}
							isLoading={isExportLoading}
							onChange={handleFormatChange}
						/>
					)}
				</>
			) : (
				<EmptyTable isLoading={loadingTags} />
			)}
		</>
	)
}

export default TagsPage
