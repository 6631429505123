import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'libs'

import EllipsisMenu from 'components/Ui/EllipsisMenu'

export type CategoryProps = {
	id: number
	name: string
	products_count: number
	checked: boolean
	openDeleteModal: (id: number) => void
}

const Category = ({ id, name, products_count, openDeleteModal }: CategoryProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const redirectToEditPage = () => navigate(`/categories/${id}`)

	const ellipsisMenuOptions = [
		{
			label: t('categories.category.ellipsisMenu.edit'),
			action: redirectToEditPage
		},
		{
			label: t('categories.category.ellipsisMenu.delete'),
			action: () => openDeleteModal(id)
		}
	]

	return (
		<article className="relative flex flex-col text-xs font-semibold text-dustyGray p-3 bg-white border border-athensGray shadow-[0px_4px_4px_-2px_rgba(0,0,0,0.12)] rounded">
			<span>
				{name ? (
					<>
						<span className="font-bold">{t('categories.category.name')}</span>{' '}
						<NavLink to={`/categories/${id}`}>{name}</NavLink>
					</>
				) : (
					''
				)}
			</span>
			<span>
				{products_count ? (
					<>
						<span className="font-bold">{t('categories.category.products_count')}</span>{' '}
						{products_count}
					</>
				) : (
					''
				)}
			</span>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</article>
	)
}

export default Category
