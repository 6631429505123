import { PropsWithChildren } from 'react'
import { cn } from 'utils/style'

type IconWrapperProps = PropsWithChildren<{ iconBgColor: string; iconColor: string }>

export const IconWrapper = ({ children, iconBgColor, iconColor }: IconWrapperProps) => (
	<div
		className={cn(
			'rounded-full w-10 h-10 p-2 flex items-center justify-center',
			iconBgColor,
			iconColor
		)}>
		{children}
	</div>
)
