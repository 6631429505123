import { useContext } from 'react'
import styled from 'styled-components'

import { StoreSettingsContext } from 'store/StoreSettingsContext'
import { WithClassName } from 'types/general'

type ILogo = WithClassName

const Logo = ({ className }: ILogo) => {
	const storeCtx = useContext(StoreSettingsContext)

	if (storeCtx?.storeSettings?.logo) return <StyledImg src={storeCtx?.storeSettings?.logo} />

	return (
		<LogoEl className={className}>
			<h2>logo</h2>
		</LogoEl>
	)
}

const LogoEl = styled.div`
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: #424242;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
`

const StyledImg = styled.img`
	max-height: 60px;
	margin: 0 auto;
`

export default Logo
