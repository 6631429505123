import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { cn } from 'utils/style'

import type { ButtonProps } from './types'

export const Button = ({
	text,
	icon,
	type = 'button',
	className,
	disabled = false,
	isLoading = false,
	styleType = 'primary',
	onClick
}: ButtonProps) => {
	return (
		<button
			type={type}
			className={cn(
				'relative rounded-lg py-1.5 px-9 focus:outline-none text-white',
				styleType === 'primary' && 'bg-accent',
				styleType === 'primary' && disabled && 'bg-spindle',
				styleType === 'cancel' && 'bg-fiord',
				styleType === 'delete' && 'bg-red',
				icon && 'px-9',
				className
			)}
			disabled={disabled || isLoading}
			onClick={onClick}>
			<span className="flex items-center justify-center">
				{!isLoading ? (
					text
				) : (
					<FontAwesomeIcon className="animate-spin text-2xl min-w-16" icon={faSpinner} />
				)}{' '}
				{icon && <FontAwesomeIcon className="absolute right-4" icon={icon} />}
			</span>
		</button>
	)
}
