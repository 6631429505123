import { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'

import { cn } from 'utils/style'

type NavItemProps = {
	to: string
	icon: ReactNode
	text: string
	end?: boolean
	onClick: () => void
}

export const NavItem = ({ to, text, icon, end = false, onClick }: NavItemProps) => {
	return (
		<NavLink
			to={to}
			className="flex rounded-md py-2.5 px-4 font-semibold text-emperor text-sm items-center gap-4 [&.active]:bg-selago [&.active>*]:text-accent"
			onClick={onClick}
			end={end}>
			<span className={cn('w-5 text-grayChateau')}>{icon}</span>
			<span>{text}</span>
		</NavLink>
	)
}
