import { NavLink, useSearchParams } from 'react-router-dom'
import { theme } from 'twin.macro'

import { useTranslation, format, parseISO } from 'libs'
import { useUserRoles } from 'hooks'
import Status from 'components/Booking/Status'
import AdvancedStatus from 'components/Status/AdvancedStatus'
import { Currency } from 'components/Ui'
import NoteStatus from 'components/Status'

import { Booking as BookingType } from 'pages/booking/types'

export const useColumns = () => {
	const { t } = useTranslation()
	const { isStaff } = useUserRoles()
	const [searchParams] = useSearchParams()
	const locale = navigator.language || 'is'
	const qtyFmt = new Intl.NumberFormat(locale, {})

	return [
		{
			label: t('sales.tableHeaders.booking'),
			renderCell: (item: any) => (
				<NavLink
					style={{ color: '#0b61ff' }}
					to={
						searchParams.toString()
							? `/bookings/${item.id}?${searchParams.toString()}`
							: `/bookings/${item.id}`
					}>
					{item.ref_nr}
				</NavLink>
			),
			select: true,
			pinLeft: true
		},
		{
			label: t('bookings.tableHeaders.created'),
			renderCell: (item: any) => (
				<div>
					<div>{format(parseISO(item.created), 'yyyy-MM-dd')}</div>
					<div>{format(parseISO(item.created), 'HH:mm:ss')}</div>
				</div>
			)
		},
		// customers only see active bookings.
		...(isStaff
			? [
					{
						label: t('bookings.tableHeaders.status'),
						renderCell: (item: any) => <Status status={item.is_active} />
					},
					{
						renderCell: (item: any) => (
							<div className="flex flex-col">
								<div>{item.user?.name}</div>
								<span className="text-xs">{item.user?.email}</span>
							</div>
						),
						label: t('bookings.tableHeaders.user')
					}
				]
			: [
					{
						label: '',
						renderCell: () => ''
					}
				]),
		{
			renderCell: (item: any) => item.booth?.booth_type.name,
			label: t('bookings.tableHeaders.type')
		},
		{
			renderCell: (item: BookingType) => item.booth?.number && `#${item.booth?.number}`,
			label: t('bookings.tableHeaders.booth')
		},
		{
			renderCell: (item: BookingType) => item.rental?.duration,
			//differenceInDays(new Date(item.end_date), new Date(item.start_date)),
			label: t('bookings.tableHeaders.days')
		},
		{
			renderCell: (item: BookingType) => format(new Date(item.start_date), 'dd.MM.yyyy'),
			label: t('bookings.tableHeaders.from')
		},
		{
			renderCell: (item: BookingType) => format(new Date(item.end_date), 'dd.MM.yyyy'),
			label: t('bookings.tableHeaders.to')
		},
		{
			renderCell: (item: BookingType) => <AdvancedStatus status={item.payment_status} />,
			label: t('bookings.tableHeaders.payment')
		},
		{
			renderCell: (item: BookingType) => item.payment_method,
			label: t('bookings.tableHeaders.paymentMethod')
		},
		{
			renderCell: (item: BookingType) => qtyFmt.format(+item.sold_quantity),
			label: t('bookings.tableHeaders.productsSold')
		},
		{
			renderCell: (item: BookingType) => (
				<Currency price={{ amount: item.sold_total, currency: item.total_value.currency }} />
			),
			label: t('bookings.tableHeaders.amountSold')
		},
		{
			renderCell: (item: BookingType) => qtyFmt.format(item.remaining_products),
			label: t('bookings.tableHeaders.productsRemaining')
		},
		{
			renderCell: (item: BookingType) => <Currency price={item.total_value} />,
			label: t('bookings.tableHeaders.totalValue')
		},
		// notes are internal for stores.
		...(isStaff
			? [
					{
						renderCell: (item: BookingType) => (
							<NoteStatus
								status={!!item.store_notes}
								showText={false}
								deactivatedColor={theme`colors.red`}
							/>
						),
						label: t('bookings.tableHeaders.notes')
					}
				]
			: [])
	]
}
