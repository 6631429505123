import { useContext, useEffect } from 'react'
import { useTranslation } from 'libs'
import * as yup from 'yup'
import Header from 'components/Header'
import Input from 'components/Ui/Input'
import { useForm } from 'react-hook-form'
import { AuthContext } from 'store/AuthContext'
import { useAxiosPrivate } from 'hooks'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useToast } from 'hooks'
import SecurityCard from 'components/SecurityCard'
import { Wrapper, StyledForm, Row } from './styles'
import FormSubtitle from 'components/Ui/Form/Subtitle'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import { Country } from 'react-phone-number-input'
import PhoneNumberInput from 'components/Ui/PhoneNumberInput'
import useSelect from 'hooks/useSelect'
import { yupResolver } from '@hookform/resolvers/yup'

const ProfilePage = () => {
	const { t } = useTranslation()

	const settingsCtx = useContext(StoreSettingsContext)
	const { auth } = useContext(AuthContext)
	const axiosPrivate = useAxiosPrivate()
	const successMessage = useToast({ type: 'success', message: t('profile.form.success') })
	const failMessage = useToast({ type: 'error', message: t('profile.form.error') })
	const queryClient = useQueryClient()

	const isIceland = settingsCtx?.storeSettings?.country == 'IS'
	const requireSSN = isIceland

	const bankInfoYup = isIceland
		? yup.object({
				ledger: yup.string().max(2, t('validation.editUser.bankInfo.ledger.max')).required(),
				account: yup.string().max(6, t('validation.editUser.bankInfo.account.max')).required(),
				branch: yup.string().max(4, t('validation.editUser.bankInfo.branch.max')).required()
			})
		: yup.object({
				branch: yup.string().max(4, t('validation.editUser.bankInfo.branch.max')).required(),
				// ledger: yup.string().max(2, t('validation.editUser.bankInfo.ledger.max')).required(),
				account: yup.string().max(10, t('validation.editUser.bankInfo.account.max')).required()
			})
	const editUserValidationSchema = yup.object({
		email: yup.string().required(t('validation.editUser.email.required')),
		// phone_number: yup
		// 	.string()
		// 	.matches(/^\+?\d*$/, t('validation.editUser.phoneNumber.matches'))
		// 	.required(t('validation.editUser.phoneNumber.required')),
		// bank_info: bankInfoYup,
		address: yup.object({
			address: yup.string().required(t('validation.editUser.address.address.required')),
			postal_code: yup.string().required(t('validation.editUser.address.postalCode.required')),
			city: yup.string().required(t('validation.editUser.address.city.required'))
		}),
		bank_info_branch: yup.string().max(4, t('validation.editUser.bankInfo.branch.max')).required(),
		// ledger: yup.string().max(2, t('validation.editUser.bankInfo.ledger.max')).required(),
		bank_info_account: yup
			.string()
			.max(10, t('validation.editUser.bankInfo.account.max'))
			.required()
		// country: yup.object({
		// 	value: yup.string().required(t('validation.editUser.country.value.required'))
		// })
	})

	const {
		register,
		handleSubmit,
		setValue,
		control,
		trigger,
		formState: { errors }
	} = useForm({ mode: 'onChange', resolver: yupResolver(editUserValidationSchema) })

	const fetchCountries = () =>
		axiosPrivate
			.options('/me/')
			.then((res) => res?.data?.actions?.PUT?.address?.children?.country?.choices)

	const { data: countries, isLoading: loadingCountries } = useQuery(
		'fetchCountries',
		fetchCountries
	)
	const { handleSelectChange } = useSelect({ setValue, trigger })

	// const editUserValidationSchema = yup.object({
	// 	// email: yup.string().required(t('validation.editUser.email.required')),
	// 	// phone_number: yup
	// 	// 	.string()
	// 	// 	.matches(/^\+?\d*$/, t('validation.editUser.phoneNumber.matches'))
	// 	// 	.required(t('validation.editUser.phoneNumber.required')),
	// 	bank_info: bankInfoYup,
	// 	address: yup.object({
	// 		address: yup.string().required(t('validation.editUser.address.address.required')),
	// 		postal_code: yup.string().required(t('validation.editUser.address.postalCode.required')),
	// 		city: yup.string().required(t('validation.editUser.address.city.required'))
	// 	}),
	// 	country: yup.object({
	// 		value: yup.string().required(t('validation.editUser.country.value.required'))
	// 	})
	// })

	// const {
	// 	handleSubmit,
	// 	register,
	// 	setValue,
	// 	control,
	// 	trigger,
	// 	setError,
	// 	formState: { errors }
	// } = useForm({
	// 	mode: 'onChange',
	// 	resolver: yupResolver(editUserValidationSchema)
	// })

	useEffect(() => {
		setValue('name', auth?.user?.name)
		setValue('email', auth?.user?.email)
		setValue('phone_number', auth?.user?.phone_number)
		setValue('ssn', auth?.user?.ssn)
		setValue('bank_info_ledger', auth?.user?.bank_info_ledger)
		setValue('bank_info_branch', auth?.user?.bank_info_branch)
		setValue('bank_info_account', auth?.user?.bank_info_account)

		setValue('address.address', auth?.user?.address?.address)
		setValue('address.city', auth?.user?.address?.city)
		setValue('address.postal_code', auth?.user?.address?.postal_code)
		// if (countries && auth?.user?.address.country) {
		// 	setValue('country', {
		// 		value: auth?.user?.address.country,
		// 		label: countries.find((country: any) => country.value === auth?.user?.address.country)
		// 			.display_name
		// 	})
		// }
	}, [auth.user])

	const { mutate: editUser, isLoading: editUserLoading } = useMutation(
		(data) => {
			return axiosPrivate.patch('/me/', data)
		},
		{
			onSuccess: () => {
				successMessage()

				queryClient.invalidateQueries('fetchMe')
			},
			onError: () => {
				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		editUser(data)
	}

	return (
		<>
			<Header title={t('profile.title')} />
			<Wrapper>
				<StyledForm
					submitText={t('profile.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={editUserLoading}>
					<FormSubtitle title={t('profile.form.account')} />
					<Input
						label={t('profile.form.email')}
						id="email"
						{...register('email')}
						type="email"
						error={(errors as any).email}
					/>

					<FormSubtitle title={t('profile.form.personal')} />
					<Input
						label={t('profile.form.name')}
						id="name"
						{...register('name')}
						error={(errors as any).name}
					/>
					{requireSSN && (
						<Input
							label={t('profile.form.ssn')}
							id="ssn"
							{...register('ssn')}
							error={(errors as any).ssn}
						/>
					)}
					<PhoneNumberInput
						id="phone"
						control={control}
						defaultCountry={settingsCtx?.storeSettings?.address?.country as Country}
						label={t('profile.form.phone')}
						{...register('phone_number')}
						error={(errors as any).phone_number}
					/>

					{/*<Controller
						name="country"
						control={control}
						render={({ field }) => (
							<Select
								id="country"
								label={t('filters.country')}
								options={countries?.map((option: any) => ({
									label: option.display_name,
									value: option.value
								}))}
								{...field}
								isLoading={loadingCountries}
								error={(errors as any).country?.value}
								onChange={(data: any) =>
									handleSelectChange({
										id: 'country',
										value: data?.value,
										label: data?.label
									})
								}
							/>
						)}
							/>*/}
					<Input
						id="address"
						{...register('address.address')}
						label={t('store.form.address')}
						error={(errors as any).address?.address}
					/>
					<Row>
						<Input
							id="city"
							{...register('address.city')}
							label={t('store.form.city')}
							error={(errors as any).address?.city}
						/>
						<Input
							id="postal_code"
							{...register('address.postal_code')}
							label={t('store.form.postal')}
							error={(errors as any).address?.postal_code}
						/>
					</Row>

					<FormSubtitle title={t('profile.form.banking')} />
					<Row>
						<Input
							label={t('profile.form.bank_info_branch')}
							id="bank_info_branch"
							error={(errors as any).bank_info_branch}
							{...register('bank_info_branch')}
						/>
						{settingsCtx?.storeSettings?.country == 'IS' && (
							<Input
								label={t('profile.form.bank_info_ledger')}
								id="bank_info_ledger"
								error={(errors as any).bank_info_ledger}
								{...register('bank_info_ledger')}
							/>
						)}
					</Row>
					<Input
						label={t('profile.form.bank_info_account')}
						id="bank_info_account"
						error={(errors as any).bank_info_account}
						{...register('bank_info_account')}
					/>
				</StyledForm>
				<SecurityCard passwordReset={false} passwordChangeLink="password-change" />
			</Wrapper>
		</>
	)
}

export default ProfilePage
