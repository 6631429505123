import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'libs'
import * as yup from 'yup'

import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import Select from 'components/Ui/Select'
import { useFetchInfinite } from 'hooks'
import Checkbox from 'components/Ui/Checkbox'
import { useAxiosPrivate } from 'hooks'
import { useFetchData } from 'hooks'
import { useToast } from 'hooks'
import { HeaderWrapper, StyledHeader, Wrapper, Row } from './styles'
import { HistoryButtonWrapper } from 'components/Ui/Button/History'
import HistoryButton from 'components/Ui/Button/History'
import { BackButton } from 'components/Ui/Button/Back'
import ColorPicker from 'components/ColorPicker'
// import { addRentalValidationSchema } from 'schemas/validation'

const RentalPage = () => {
	const { t } = useTranslation()
	const addRentalValidationSchema = yup.object({
		duration: yup.string().required(t('validation.addRental.duration.required')),
		price: yup.object({
			amount: yup.string().required(t('validation.addRental.price.amount.required'))
		}),
		original_price: yup.object({
			amount: yup.string().required(t('validation.addRental.originalPrice.amount.required'))
		})
	})
	const {
		handleSubmit,
		control,
		register,
		setValue,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(addRentalValidationSchema)
	})
	const axiosPrivate = useAxiosPrivate()
	const params = useParams()
	const navigate = useNavigate()
	const queryClient = useQueryClient()

	const successMessage = useToast({ type: 'success', message: t('rental.form.success') })
	const failMessage = useToast({ type: 'error', message: t('rental.form.error') })
	const defaultColor = '#FFFFFF'
	const [rentalColor, setRentalColor] = useState(defaultColor)

	const { data: boothTypes, fetchNextPage } = useFetchInfinite({
		name: 'fetchBoothTypes',
		endpoint: '/booth-types/'
	})

	const { mutate: editRental, isLoading: editRentalLoading } = useMutation(
		(data) => {
			return axiosPrivate.patch(`/rentals/${params.id}/`, data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchRentals')
				successMessage()

				navigate('/rentals')
			},
			onError: () => {
				failMessage()
			}
		}
	)

	useFetchData({
		name: 'fetchRental',
		endpoint: `/rentals/${params.id}/`,
		options: {
			onSuccess: (res: any) => {
				setValue('commission', res?.data?.commission)
				setValue('price.amount', res?.data?.price.amount)
				setValue('original_price.amount', res?.data?.original_price.amount)
				setValue('is_active', res?.data?.is_active)
				setValue('duration', res?.data?.duration)
				setValue('currency', {
					value: res?.data?.price?.currency,
					label: res?.data?.price?.currency
				})
				if (res?.data?.booth_type) {
					setValue('booth_type', {
						value: res?.data?.booth_type,
						label: res?.data?.booth_type_name?.name
					})
				}
				if (res?.data.color) {
					setRentalColor(res?.data.color)
				}
			}
		}
	})
	const handleRentalColorChange = (data: any) => {
		const { hex } = data

		setRentalColor(hex)
	}

	const fetchCurrencies = () =>
		axiosPrivate.options('/settings/').then((res) => res?.data?.actions?.PUT?.currency?.choices)

	const { data: currencies } = useQuery('fetchCurrencies', fetchCurrencies)

	const onSubmit = (data: any) => {
		const updatedRental = {
			...data,
			price: {
				amount: data.price.amount,
				currency: data.currency?.value
			},
			original_price: {
				amount: data.original_price.amount,
				currency: data.currency?.value
			},
			booth_type: data.booth_type?.value,
			color: rentalColor
		}

		editRental(updatedRental)
	}

	return (
		<>
			<HeaderWrapper>
				<StyledHeader title={t('rental.title')} />
				<HistoryButtonWrapper>
					<BackButton to="/rentals" mobile />
					<HistoryButton to={`/rentals/${params.id}/history`} />
				</HistoryButtonWrapper>
			</HeaderWrapper>
			<Wrapper>
				<Form
					submitText={t('rental.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={editRentalLoading}>
					<Row>
						{/* <Controller
							name="store"
							control={control}
							render={({ field }) => (
								<StyledSelect
									id="store"
									placeholder={t('rental.form.store.placeholder')}
									label={t('rental.form.store.label')}
									options={[
										{
											value: '',
											label: storeCtx?.storeSettings?.name
										}
									]}
									{...field}
									disabled={true}
								/>
							)}
						/> */}
						<Controller
							name="booth_type"
							control={control}
							render={({ field }) => (
								<Select
									id="booth-type"
									placeholder={t('filters.placeholder')}
									label={t('filters.boothType')}
									options={boothTypes?.pages
										.reduce((total: any, page: any) => [...total, ...page.results], [])
										.map((boothType: any) => ({ label: `${boothType.name}`, value: boothType.pk }))}
									{...field}
									handleInfiniteScroll={fetchNextPage}
								/>
							)}
						/>
					</Row>
					<Row>
						<Input
							id="duration"
							label={t('rental.form.duration')}
							type="number"
							step="1"
							{...register('duration')}
							error={(errors as any).duration}
							required={true}
						/>
						<Input
							id="commission"
							label={t('rental.form.commission')}
							{...register('commission')}
							type="number"
							step="0.01"
						/>
					</Row>
					<Row>
						<Controller
							name="currency"
							control={control}
							render={({ field }) => (
								<Select
									id="currency"
									placeholder={t('rental.form.currency.placeholder')}
									label={t('rental.form.currency.label')}
									options={currencies?.map((currency: any) => ({
										value: currency.value,
										label: currency.display_name
									}))}
									{...field}
								/>
							)}
						/>
						<Input
							id="price"
							label={t('rental.form.price')}
							{...register('price.amount')}
							error={(errors as any).price?.amount}
							required={true}
						/>
						<Input
							id="originalPrice"
							label={t('rental.form.originalPrice')}
							{...register('original_price.amount')}
							error={(errors as any).original_price?.amount}
							required={true}
						/>
					</Row>
					<Checkbox
						{...register('is_active')}
						label={t('rental.form.active.label')}
						desc={t('rental.form.active.desc')}
					/>
					<ColorPicker
						label={t('rental.form.rentalColor.label')}
						color={rentalColor}
						onChange={handleRentalColorChange}
					/>
				</Form>
			</Wrapper>
		</>
	)
}

export default RentalPage
