import { useTranslation } from 'libs'

import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import HistoryTable from 'components/HistoryTable'
import Header from 'components/Header'

const SaleHistoryPage = () => {
	const { t } = useTranslation()
	const params = useParams()

	const { data: saleHistory, isLoading } = useFetchData({
		name: 'fetchSaleHistory',
		endpoint: `/sales/${params.id}/history/`
	})

	return (
		<>
			<Header title={t('saleHistory.title')} />
			<HistoryTable results={saleHistory?.data} isLoading={isLoading} />
		</>
	)
}

export default SaleHistoryPage
