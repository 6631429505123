import styled from 'styled-components'
import { theme } from 'twin.macro'
import CardWrapper from 'components/Card/Wrapper'
import Header from 'components/Header'
import { NavLink } from 'react-router-dom'

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: flex-start;

	@media (min-width: ${theme`screens.lg`}) {
		flex-direction: row;
		max-width: 461px;
		justify-content: space-between;
		align-items: center;
	}
`

export const StyledHeader = styled(Header)`
	width: 100%;
`

export const Wrapper = styled.div`
	padding: 0 1rem;
	display: flex;
	column-gap: 40px;
	flex-direction: column;

	@media (min-width: ${theme`screens.lg`}) {
		flex-direction: row;
		padding: 0;
	}

	& > * {
		flex: 1;
	}
`

export const Row = styled.div`
	gap: 10px;
	display: flex;
	flex-direction: column;

	@media (min-width: ${theme`screens.md`}) {
		justify-content: space-between;
		flex-direction: row;

		& > * {
			flex: 1;
		}
	}
`

export const Info = styled(CardWrapper)`
	padding: 30px;
	color: ${theme`colors.emperor`};
`

export const InfoRow = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	font-size: 0.875rem;
	font-weight: 600;
`

export const InfoHeader = styled.h3`
	font-size: 1.125rem;
	font-weight: 600;
`

export const InfoHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
`

export const InfoHeaderLink = styled(NavLink)`
	color: ${theme`colors.blueRibbon`};
`

export const SalesTableWrapper = styled.div`
	font-size: 12px;
	color: #676767;
	font-weight: 700;
	opacity: 1;
`

export const Right = styled.div`
	margin: 20px 0 0;
	flex-direction: column;
	display: flex;
	gap: 20px;
	max-width: 461px;
	margin: 0 auto;
	width: 100%;

	@media (min-width: ${theme`screens.lg`}) {
		max-width: 800px;
		margin: 20px auto 0;
	}
`
export const ProductLinkWrapper = styled.div`
	margin-top: 30px;
	padding: 10px 14px 20px;
	max-width: 461px;
	width: 100%;
	text-align: center;
`

export const ProductLink = styled(NavLink)`
	position: relative;
	display: inline;
	border-radius: 6px;
	padding: 10px 20px;
	text-align: center;
	margin: 30px auto;
	background-color: ${theme`colors.mercury`};
	color: ${theme`colors.mineShaft`};

	&:focus {
		outline: none;
	}
`
