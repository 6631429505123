import styled from 'styled-components'
import { theme } from 'twin.macro'
import { Styles } from 'react-modal'
import { MonthlyCalendar } from '@zach.codes/react-calendar'

export const customModalStyles: Styles = {
	overlay: {
		zIndex: '1000',
		backgroundColor: 'rgba(0,0,0,0.5)'
	}
}

export const customTableStyles = {
	Table: `
		border: 1px solid ${theme`colors.iron`};
		border-radius: 8px;
		align-content: start;
		height: auto;
		max-height: 100%;

		th {
			border-bottom: 1px solid ${theme`colors.iron`} !important;
			background-color: ${theme`colors.athensGray2`};
			font-weight: 600;

			&:first-of-type {
				z-index: 5;
			}
		}

		tr:nth-of-type(even) {
			background-color: ${theme`colors.alabaster2`};
		}

		& .row-select-selected,
		& .row-select-single-selected {
			background-color: ${theme`colors.iron`} !important;
		}

	`,
	BaseCell: `
			font-size: 0.875rem;
			font-weight: 600;
			color: ${theme`colors.boulder2`};
			border: none !important;
			padding: 10px;

			&:nth-of-type(1) {
				left: 0px;
				position: sticky;
				z-index: 3;
			}

			&:nth-of-type(2) {
				left: 40px;
			}

			& > div:last-of-type {
				overflow: visible;
			}
		`
}

export const StyledCalendar = styled(MonthlyCalendar)``

export const FiltersContainer = styled.div`
	display: flex;
	gap: 20px;
	align-items: flex-end;
	flex-wrap: wrap;
`
