import { useTranslation } from 'libs'
import Select from 'components/Ui/Select'
import { GB, IS, SE, DK } from 'country-flag-icons/react/3x2'
import { OptionProps, SingleValueProps, components } from 'react-select'

import { cn } from 'utils/style'

const flags = {
	is: <IS className="w-6 h-6" />,
	en: <GB className="w-6 h-6" />,
	se: <SE className="w-6 h-6" />,
	dk: <DK className="w-6 h-6" />
}

const CustomOption = ({ children, innerRef, innerProps, isSelected, data }: OptionProps<any>) => {
	return (
		<div
			ref={innerRef}
			{...innerProps}
			className={cn(
				'cursor-pointer block text-[length:inherit] w-full select-none py-2 px-3 whitespace-nowrap flex gap-2 items-center hover:bg-dodgerBlue hover:text-white',
				isSelected ? 'bg-dodgerBlue text-white' : 'text-doveGray bg-white'
			)}>
			{data?.value ? flags[data.value as keyof typeof flags] : null}
			{children}
		</div>
	)
}

const LanguageSwitcher = () => {
	const { i18n, t } = useTranslation()
	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
	}

	const languages = [
		{
			label: t('languages.is'),
			code: 'is'
		},
		{
			label: t('languages.en'),
			code: 'en'
		},
		{
			label: t('languages.dk'),
			code: 'dk'
		},
		{
			label: t('languages.se'),
			code: 'se'
		}
	]

	const CustomSingleValue = ({ ...props }: SingleValueProps<any>) => (
		<components.SingleValue {...props} className="flex gap-2 items-center">
			{props.data?.value ? flags[i18n.language as keyof typeof flags] : null}
			{languages.find((language) => language.code === i18n.language)?.label}
		</components.SingleValue>
	)

	const handleLanguageChange = (e: { value: string; label: string }) => {
		changeLanguage(e.value)
	}

	return (
		<div className="flex gap-3">
			<Select
				id="language"
				isClearable={false}
				defaultValue={{
					value: languages.find((language) => language.code === i18n.language)?.code,
					label: languages.find((language) => language.code === i18n.language)?.label
				}}
				options={languages.map((language) => ({
					value: language.code,
					label: language.label
				}))}
				onChange={handleLanguageChange}
				components={{
					Option: CustomOption,
					IndicatorSeparator: () => null,
					SingleValue: CustomSingleValue
				}}
				className="w-full"
			/>
		</div>
	)
}

export default LanguageSwitcher
