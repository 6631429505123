import { useCallback } from 'react'
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import debounce from 'lodash.debounce'

import Input from 'components/Ui/Input'
import { defaultConfig } from 'config/default'
import { cn } from 'utils/style'
import { WithClassName } from 'types/general'

type ISearchInput = WithClassName<{
	placeholder: string
	label: string
	onChange: (e: any) => void
}>

const SearchInput = ({ label, placeholder, className, onChange }: ISearchInput) => {
	const debouncedChangeHandler = useCallback(
		debounce(onChange, defaultConfig.searchDebounceTime),
		[]
	)

	return (
		<Input
			id="search"
			name="search"
			label={label}
			placeholder={placeholder}
			icon={faMagnifyingGlass}
			onChange={debouncedChangeHandler}
			className={cn('text-emperor [&>div>div]:bg-transparent', className)}
		/>
	)
}

export default SearchInput
