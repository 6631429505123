import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'libs'

interface IPagination {
	prev: string | null
	next: string | null
	onClick: (data: any) => void
}

const Pagination = ({ prev, next, onClick }: IPagination) => {
	const { t } = useTranslation()

	return (
		<div className="flex items-center gap-4 text-sm">
			{prev ? (
				<button className="flex items-center gap-2" onClick={() => onClick(prev)}>
					<FontAwesomeIcon icon={faChevronLeft} />
					<span>{t('pagination.prev')}</span>
				</button>
			) : null}{' '}
			{next ? (
				<button className="flex items-center gap-2" onClick={() => onClick(next)}>
					<span>{t('pagination.next')}</span>
					<FontAwesomeIcon icon={faChevronRight} />
				</button>
			) : null}
		</div>
	)
}

export default Pagination
