import { useState } from 'react'
import { useTranslation } from 'libs'
import { Column } from '@table-library/react-table-library/compact'
import { CompactTable } from '@table-library/react-table-library/compact'
import { useTheme } from '@table-library/react-table-library/theme'
import { getTheme } from '@table-library/react-table-library/baseline'
import { Currency } from 'components/Ui'
import { InfoHeader, SalesTableWrapper } from './styles'
import { customTableStyles } from 'styles/general'
import { ImageElement, InfoDetails, Name } from 'components/Product'
import { useFetchData } from 'hooks'
import { Spinner } from 'components/Ui/Spinner'
import { InfoHeaderContainer, InfoHeaderLink } from 'pages/booking'
import TableImage from 'components/Ui/Table/Image'

export interface UserProductsTableProps {
	owner_id?: string
}

export const UserProductsTable = ({ owner_id: user_id }: UserProductsTableProps) => {
	const { t } = useTranslation()
	const [productsData, setProductsData] = useState({ nodes: [] })

	const { isFetching: productsLoading } = useFetchData({
		name: 'fetchProducts',
		query: `owner_id=${user_id}`,
		endpoint: '/products/',
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setProductsData({ nodes: results })
			}
		}
	})

	if (!productsData?.nodes?.length) {
		// TODO: empty sales content
		return (
			<>
				<InfoHeader>Products</InfoHeader>
				<div>No products yet</div>
			</>
		)
	}

	const columns: Column[] = [
		{
			label: t('products.tableHeaders.product'),
			renderCell: (item: any) => (
				<ImageElement>
					<TableImage className="flex items-center" src={item.image_thumbnail} />
					<InfoDetails>
						<Name to={`/products/${item.id}`}>{item.name}</Name>
						<span className="text-xs">{item.sku}</span>
					</InfoDetails>
				</ImageElement>
			),

			select: true,
			pinLeft: true
		},
		{
			label: t('products.tableHeaders.category'),
			renderCell: (item: any) => (item.category?.id ? item.category.name : '')
		},
		{
			label: t('products.tableHeaders.price'),
			renderCell: (item: any) => <Currency price={item.price} />
		},
		{
			label: t('products.tableHeaders.quantity'),
			renderCell: (item: any) => item.quantity
		},

		{
			label: t('products.tableHeaders.sold'),
			renderCell: (item: any) => item.sum_sold_quantity
		}
	]

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
			${customTableStyles.Table};
			--data-table-library_grid-template-columns: 165px repeat(${columns.length - 1}, 1fr);
		`
	}

	const theme = useTheme([getTheme(), desktopTableStyles])

	return (
		<>
			<InfoHeaderContainer>
				<InfoHeader>Products</InfoHeader>
				{!!productsData?.nodes?.length && (
					<InfoHeaderLink to={'/products?owner_id=' + user_id}>View all</InfoHeaderLink>
				)}
			</InfoHeaderContainer>
			<SalesTableWrapper>
				{productsLoading && <Spinner></Spinner>}
				{productsData.nodes != null &&
				Array.isArray(productsData?.nodes) &&
				productsData?.nodes.length ? (
					<CompactTable
						columns={columns}
						data={productsData}
						theme={theme}
						isLoading={productsLoading}
						layout={{ custom: true, horizontalScroll: true, fixedHeader: true }}></CompactTable>
				) : (
					<></>
				)}
			</SalesTableWrapper>
		</>
	)
}
