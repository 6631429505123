import Select from 'react-select'
import { theme } from 'twin.macro'

import Control from './Control'
import { WithClassName } from 'types/general'

interface ISortOption {
	value: string
	label: string
}

type ISortSelect = WithClassName<{
	id: string
	options: ISortOption[]
	onChange: (data: any) => void
}>

const customStyles = {
	option: (provided: any, state: any) => ({
		...provided,
		color: state.isSelected || state.isFocused ? '#fff' : '#676767',
		backgroundColor: state.isSelected || state.isFocused ? '#465CFF' : '#fff',
		cursor: 'pointer',
		fontSize: '0.75rem'
	}),
	control: (base: any, state: any) => ({
		boxShadow: state.isFocused
			? '0 0 5px #465cff'
			: '0px 5px 4px -4px rgba(0, 0, 0, 0.02), 0px 1px 2px rgba(0, 0, 0, 0.06)',
		border: `1px solid ${theme`colors.iron`}`,
		borderRadius: '8px',
		backgroundColor: theme`colors.white`,
		cursor: 'pointer'
	}),
	menu: (base: any) => ({
		...base,
		width: '200px',
		right: '0',
		transform: 'none',
		zIndex: '100'
	}),
	menuList: (base: any) => ({
		...base
	}),
	dropdownIndicator: () => ({
		display: 'none'
	}),
	valueContainer: (base: any) => ({
		...base,
		position: 'absolute',
		opacity: '0'
	})
}

const SortSelect = ({ options, className, onChange }: ISortSelect) => {
	const handleSort = (data: any) => {
		onChange({ id: 'ordering', ...data })
	}

	return (
		<Select
			className={className}
			styles={customStyles}
			options={options}
			isSearchable={false}
			components={{
				IndicatorSeparator: () => null,
				Control: ({ ...rest }) => <Control {...rest} />
			}}
			onChange={handleSort}
		/>
	)
}

export default SortSelect
