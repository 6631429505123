import { Link } from 'react-router-dom'

import { useTranslation } from 'libs'

type SecurityCardProps = {
	passwordReset?: boolean
	passwordChange?: boolean
	passwordChangeLink: string
}

const SecurityCard = ({
	passwordChange = true,
	passwordReset = true,
	passwordChangeLink
}: SecurityCardProps) => {
	const { t } = useTranslation()

	return (
		<div className="p-8 mx-auto w-full max-w-[461px] md:max-w-[500px] md:mt-5 md:mx-auto">
			<h3 className="text-lg mb-2.5 font-semibold text-emperor">{t('profile.security.title')}</h3>
			<div className="flex justify-between gap-2.5">
				{passwordReset ? (
					<Link
						className="bg-scorpion text-white w-full block text-center p-3 font-semibold rounded-lg"
						to="/password-reset">
						{t('profile.security.reset')}
					</Link>
				) : null}

				{passwordChange ? (
					<Link
						className="bg-scorpion text-white w-full block text-center p-3 font-semibold rounded-lg"
						to={passwordChangeLink}>
						{t('profile.security.change')}
					</Link>
				) : null}
			</div>
		</div>
	)
}

export default SecurityCard
