import { PropsWithChildren } from 'react'
import { theme } from 'twin.macro'

import { Modal } from 'components/Ui'
import { Button } from 'components/Ui'
import { t } from 'i18next'
import Select from 'components/Ui/Select'
import { defaultConfig } from 'config/default'
import { IOption } from 'interfaces/select.interface'

type IConfirmModal = PropsWithChildren<{
	isOpened: boolean
	disableButton: boolean
	isLoading?: boolean
	closeModal: () => void
	confirm: () => void
	onChange: (data: IOption) => void
}>

const customStyles = {
	overlay: {
		backgroundColor: 'rgba(0,0,0,0.5)',
		zIndex: '100'
	},
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: '350px',
		backgroundColor: theme`colors.white`,
		width: '100%',
		padding: '0',
		border: 'none',
		overflow: 'unset'
	}
}

const FormatModal = ({
	isOpened,
	closeModal,
	confirm,
	isLoading,
	disableButton,
	onChange
}: IConfirmModal) => {
	return (
		<Modal
			isOpened={isOpened}
			closeModal={closeModal}
			title={t('formatModal.title')}
			customStyles={customStyles}>
			<div className="px-4 pb-4 text-sm text-scorpion">
				<Select
					id="exportFormat"
					label={t('formatModal.select.label')}
					options={defaultConfig.exportFormatOptions}
					onChange={onChange}
				/>
			</div>
			<div className="flex justify-end gap-4 bg-white p-4">
				<Button
					text={t('formatModal.confirm')}
					styleType="primary"
					onClick={confirm}
					disabled={disableButton}
					isLoading={isLoading}
				/>
			</div>
		</Modal>
	)
}

export default FormatModal
