import { useTranslation } from 'libs'
import { useUserRoles } from 'hooks'

export const useActionFilters = (isDisabled: boolean) => {
	const { t } = useTranslation()
	const { isStaff } = useUserRoles()

	return [
		{
			id: 'actions',
			label: t('bookings.filters.actions.label'),
			placeholder: t('bookings.filters.actions.placeholder'),
			options: [
				...(isStaff
					? [
							// customers cannot send welcome emails
							{
								value: 'emailWelcome',
								label: t('bookings.filters.actions.options.emailWelcome'),
								isDisabled: isDisabled
							},
							// customers cannot send end reminder emails
							{
								value: 'emailReminder',
								label: t('bookings.filters.actions.options.emailReminder'),
								isDisabled: isDisabled
							},
							// customers cannot send sms reminders
							{
								value: 'smsReminder',
								label: t('bookings.filters.actions.options.smsReminder'),
								isDisabled: isDisabled
							}
						]
					: []),
				{
					value: 'export',
					label: t('bookings.filters.actions.options.export')
				},
				...(isStaff
					? [
							// customers cannot delete bookings
							{
								value: 'delete',
								label: t('bookings.filters.actions.options.delete'),
								isDisabled: isDisabled
							}
						]
					: [])
			]
		}
	]
}

export const useSortingFilters = () => {
	const { t } = useTranslation()
	const { isStaff } = useUserRoles()

	return {
		id: 'ordering',
		label: t('bookings.filters.sort.label'),
		placeholder: t('bookings.filters.sort.placeholder'),
		options: [
			// {
			// 	value: 'store',
			// 	label: `${t('bookings.filters.sort.label')} store asc`
			// },
			// {
			// 	value: '-store',
			// 	label: `${t('bookings.filters.sort.label')} store desc`
			// },
			{
				value: 'start_date',
				label: `${t('bookings.filters.sort.label')} start_date asc`
			},
			{
				value: '-start_date',
				label: `${t('bookings.filters.sort.label')} start_date desc`
			},
			{
				value: '_end_date',
				label: `${t('bookings.filters.sort.label')} end_date asc`
			},
			{
				value: '-_end_date',
				label: `${t('bookings.filters.sort.label')} end_date desc`
			},
			...(isStaff
				? [
						// customers only see active bookings
						{
							value: 'is_active',
							label: `${t('bookings.filters.sort.label')} is_active asc`
						},
						{
							value: '-is_active',
							label: `${t('bookings.filters.sort.label')} is_active desc`
						},

						// customers only see their own bookings
						{
							value: 'user__name',
							label: `${t('bookings.filters.sort.label')} user asc`
						},
						{
							value: '-user__name',
							label: `${t('bookings.filters.sort.label')} user desc`
						}
					]
				: [])
		]
	}
}

export const useFilters = () => {
	const { t } = useTranslation()
	const { isStaff } = useUserRoles()

	return [
		// customers only receive active bookings
		...(isStaff
			? [
					{
						id: 'is_active',
						label: t('bookings.filters.status.label'),
						placeholder: t('bookings.filters.status.placeholder'),
						options: [
							{
								value: 'true',
								label: t('bookings.filters.status.options.active')
							},
							{
								value: 'false',
								label: t('bookings.filters.status.options.expired')
							}
						]
					}
				]
			: [])
	]
}
