import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'libs'

import EllipsisMenu from 'components/Ui/EllipsisMenu'

export type CouponProps = {
	id: number
	created: string
	code: string
	price_discount: {
		value: string
		is_percentage: boolean
	}
	commission_discount: {
		value: string
		is_percentage: boolean
	}
	validity: {
		expiration_date: string
		is_active: boolean
	}
	max_uses: {
		max_uses: number
		is_infinite: boolean
		uses_per_user: number
	}
	openDeleteModal: (id: number) => void
}

const Coupon = ({
	id,
	code,
	price_discount,
	commission_discount,
	openDeleteModal
}: CouponProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const redirectToEditPage = () => navigate(`/coupons/${id}`)

	const ellipsisMenuOptions = [
		{
			label: t('coupons.coupon.ellipsisMenu.edit'),
			action: redirectToEditPage
		},
		{
			label: t('coupons.coupon.ellipsisMenu.delete'),
			action: () => openDeleteModal(id)
		}
	]

	return (
		<article className="relative flex flex-col text-xs font-semibold text-dustyGray p-3 bg-white border border-athensGray shadow-[0px_4px_4px_-2px_rgba(0,0,0,0.12)] rounded">
			<span>
				{code ? (
					<>
						<span className="font-bold">{t('coupons.coupon.name')}</span>{' '}
						<NavLink to={`/coupons/${id}`}>{code}</NavLink>
					</>
				) : (
					''
				)}
			</span>
			<span>
				{price_discount ? (
					<>
						<span className="font-bold">{t('coupons.coupon.price_discount')}</span>{' '}
						{price_discount.value}
						{price_discount.is_percentage && '%'}
					</>
				) : (
					''
				)}
			</span>
			<span>
				{commission_discount ? (
					<>
						<span className="font-bold">{t('coupons.coupon.commission_discount')}</span>{' '}
						{commission_discount.value}
						{commission_discount.is_percentage && '%'}
					</>
				) : (
					''
				)}
			</span>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</article>
	)
}

export default Coupon
