import { useState, useContext } from 'react'
import { useTranslation } from 'libs'
import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import PageWrapper from 'components/Ui/PageWrapper'
import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import { useFetchData } from 'hooks'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useToast } from 'hooks'
import { useAxiosPrivate } from 'hooks'
import { Dimensions, HeaderWrapper, StyledHeader } from './styles'
import { HistoryButtonWrapper } from 'components/Ui/Button/History'
import HistoryButton from 'components/Ui/Button/History'
import { BackButton } from 'components/Ui/Button/Back'
import ColorPicker from 'components/ColorPicker'
import { AuthContext } from 'store/AuthContext'
// import { addBoothTypeValidationSchema } from 'schemas/validation'

const BoothTypePage = () => {
	const { t } = useTranslation()
	const params = useParams()
	const axiosPrivate = useAxiosPrivate()
	const navigate = useNavigate()
	const defaultColor = '#FFFFFF'
	const [primaryColor, setPrimaryColor] = useState(defaultColor)
	const [secondaryColor, setSecondaryColor] = useState(defaultColor)
	const addBoothTypeValidationSchema = yup.object({
		name: yup.string().required(t('validation.addBoothType.name.required')),
		dimensions: yup.object({
			height: yup.string().required(t('validation.addBoothType.dimensions.height.required')),
			width: yup.string().required(t('validation.addBoothType.dimensions.width.required')),
			depth: yup.string().required(t('validation.addBoothType.dimensions.depth.required'))
		})
	})
	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors }
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(addBoothTypeValidationSchema)
	})
	const successMessage = useToast({ type: 'success', message: t('boothType.form.success') })
	const failMessage = useToast({ type: 'error', message: t('boothType.form.error') })
	const queryClient = useQueryClient()
	const { auth } = useContext(AuthContext)
	const handlePrimaryColorChange = (data: any) => {
		const { hex } = data

		setPrimaryColor(hex)
	}
	const handleSecondaryColorChange = (data: any) => {
		const { hex } = data

		setSecondaryColor(hex)
	}
	useFetchData({
		name: 'fetchBoothType',
		endpoint: `/booth-types/${params.id}/`,
		options: {
			onSuccess: (res: any) => {
				setValue('name', res?.data?.name)
				setValue('description', res?.data?.description)
				setValue('dimensions.height', +res?.data?.dimensions?.height)
				setValue('dimensions.width', +res?.data?.dimensions?.width)
				setValue('dimensions.depth', +res?.data?.dimensions?.depth)
				setPrimaryColor(res?.data.primary_color ? res?.data.primary_color : defaultColor)
				setSecondaryColor(res?.data.secondary_color ? res?.data.secondary_color : defaultColor)
			},
			enabled: !!auth.accessToken
		}
	})

	const { mutate: editBoothTypeMutation, isLoading: isEditBoothTypeLoading } = useMutation(
		(data) => {
			return axiosPrivate.patch(`/booth-types/${params.id}/`, data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchBoothTypes')
				successMessage()

				navigate('/booth-types')
			},
			onError: () => {
				failMessage()
			}
		}
	)

	const onSubmit = (data: any) => {
		data.primary_color = primaryColor
		data.secondary_color = secondaryColor
		editBoothTypeMutation(data)
	}

	return (
		<>
			<HeaderWrapper>
				<StyledHeader title={t('boothType.title')} />
				<HistoryButtonWrapper>
					<BackButton to="/booth-types" mobile />
					<HistoryButton to={`/booth-types/${params.id}/history`} />
				</HistoryButtonWrapper>
			</HeaderWrapper>
			<PageWrapper>
				<Form
					submitText={t('boothType.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={isEditBoothTypeLoading}>
					<Input
						id="name"
						label={t('boothType.form.name')}
						{...register('name')}
						error={(errors as any).name}
						required={true}
					/>
					<Input
						id="description"
						label={t('boothType.form.description')}
						{...register('description')}
					/>
					<Dimensions>
						<Input
							id="height"
							label={t('boothType.form.height')}
							type="number"
							{...register('dimensions.height')}
							error={(errors as any).dimensions?.height}
							required={true}
						/>
						<Input
							id="width"
							label={t('boothType.form.width')}
							type="number"
							{...register('dimensions.width')}
							error={(errors as any).dimensions?.width}
							required={true}
						/>
						<Input
							id="depth"
							label={t('boothType.form.depth')}
							type="number"
							{...register('dimensions.depth')}
							error={(errors as any).dimensions?.depth}
							required={true}
						/>
					</Dimensions>
					<ColorPicker
						label={t('boothType.form.primaryColor')}
						color={primaryColor}
						onChange={handlePrimaryColorChange}
					/>
					<ColorPicker
						label={t('boothType.form.secondaryColor')}
						color={secondaryColor}
						onChange={handleSecondaryColorChange}
					/>
				</Form>
			</PageWrapper>
		</>
	)
}

export default BoothTypePage
