import { useTranslation } from 'libs'

import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import HistoryTable from 'components/HistoryTable'
import Header from 'components/Header'

const RentalHistoryPage = () => {
	const { t } = useTranslation()
	const params = useParams()

	const { data: rentalHistory, isLoading } = useFetchData({
		name: 'fetchRentalHistory',
		endpoint: `/rentals/${params.id}/history/`
	})

	return (
		<>
			<Header title={t('rentalHistory.title')} />
			<HistoryTable results={rentalHistory?.data} isLoading={isLoading} />
		</>
	)
}

export default RentalHistoryPage
