import { useContext } from 'react'
import { useForm } from 'react-hook-form'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'libs'
import axios from 'axios'
import * as yup from 'yup'
import useFetchStoreData from 'hooks/useFetchStoreData'
import Input from 'components/Ui/Input'
import { Button } from 'components/Ui'
import { AuthContext } from 'store/AuthContext'
import { StoreSettingsContext } from 'store/StoreSettingsContext'
import LanguageSwitcher from 'components/LanguageSwitcher'
// import { loginValidationSchema } from 'schemas/validation'

const LoginPage = () => {
	const { t } = useTranslation()
	const { setAuth } = useContext(AuthContext)
	const storeCtx = useContext(StoreSettingsContext)
	const navigate = useNavigate()
	const loginValidationSchema = yup.object({
		email: yup
			.string()
			.email(t('validation.login.email.email'))
			.required(t('validation.login.email.required')),
		password: yup
			.string()
			.required(t('validation.login.password.required'))
			.min(7, t('validation.login.password.min'))
		// .matches(
		// 	/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-])/,
		// 	'Password must contain at least 1 small letter, 1 big letter, 1 special character, 1 number'
		// )
	})
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(loginValidationSchema),
		mode: 'onChange'
	})

	useFetchStoreData({
		name: 'fetchStoreData',
		endpoint: '/store/',
		options: {
			onSuccess: async (res: any) => {
				await storeCtx?.setStoreSettings(res.data)
			},
			enabled: true
		}
	})

	const { mutate: loginMutation, isLoading: loadingLogin } = useMutation(
		(data) => {
			return axios.post('/login/', data)
		},
		{
			onSuccess: (res) => {
				localStorage.setItem('refresh', res.data.refresh)

				setAuth((prev: any) => ({ ...prev, accessToken: res.data.access }))
				navigate('/')
			},
			onError: (data: any) => {
				const errorMessage = data.response.data.detail

				setError('password', { type: 'custom', message: errorMessage })
			}
		}
	)

	const onSubmit = (data: any) => {
		loginMutation(data)
	}

	return (
		<section className="flex min-h-screen items-center justify-center p-4">
			<form
				className="w-full max-w-[362px] shadow-[0_12px_40px_rgba(0,0,0,0.12)] rounded-lg py-7 px-6"
				onSubmit={handleSubmit(onSubmit)}>
				<div className="flex justify-end text-sm text-doveGray">
					<LanguageSwitcher />
				</div>
				{storeCtx?.storeSettings?.logo && (
					<img
						className="block mt-0 mx-auto mb-5 max-h-24"
						src={storeCtx?.storeSettings?.logo}
						alt="Logo"
					/>
				)}
				<div className="mb-6">
					<h1 className="text-base text-scorpion font-semibold">{t('login.title')}</h1>
				</div>
				<div className="flex flex-col gap-2">
					<Input
						id="email"
						label={t('login.email')}
						{...register('email')}
						error={(errors as any).email}
					/>
					<Input
						id="password"
						label={t('login.password')}
						type="password"
						{...register('password')}
						error={(errors as any).password}
					/>
					<Link to="/password-reset" className="self-end text-accent font-bold text-xs">
						Reset password
					</Link>
				</div>
				<Button
					type="submit"
					text={t('login.submit')}
					icon={faArrowRight}
					isLoading={loadingLogin}
					className="bg-emperor block w-full py-2.5 text-white font-black mt-8"
				/>
			</form>
		</section>
	)
}

export default LoginPage
