import { useContext, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faBars, faTimes } from '@fortawesome/free-solid-svg-icons'

import { NavContext } from 'store/NavContext'
import SearchInput from 'components/Ui/SearchInput'
import { useDevice } from 'hooks'
import { WithClassName } from 'types/general'
import { cn } from 'utils/style'

type HeaderProps = WithClassName<{
	title: string
	handleSearch?: any
}>

const Header = ({ title, handleSearch, className }: HeaderProps) => {
	const ctx = useContext(NavContext)
	const [searchOpened, setSearchOpened] = useState(false)
	const { isMobileOrTabletHorizontal } = useDevice()

	const showSidebar = () => {
		ctx?.toggleNavOpened(true)
	}

	const closeSearch = () => setSearchOpened(false)
	const openSearch = () => setSearchOpened(true)

	return (
		<div
			className={cn(
				'flex items-center justify-between p-4 text-white lg:bg-transparent lg:p-0 w-full',
				searchOpened ? 'bg-white' : 'bg-mirage',
				className
			)}>
			{isMobileOrTabletHorizontal ? (
				!searchOpened ? (
					<>
						<div className="flex gap-2 lg:text-tundora">
							<button className="xl:hidden" onClick={showSidebar}>
								<FontAwesomeIcon icon={faBars} />
							</button>
							<h2 className="font-semibold text-lg text-white lg:text-tundora">{title}</h2>
						</div>
						{handleSearch && (
							<button className="h-7 w-8" onClick={openSearch}>
								<FontAwesomeIcon icon={faMagnifyingGlass} />
							</button>
						)}
					</>
				) : (
					<div className="flex justify-between w-full items-center gap-4 [&>div]:w-full [&_svg]:text-xl lg:hidden">
						<SearchInput
							className="border-none [&_input]:border-none [&_input]:py-0 [&_input]:text-lg [&_input]:shadow-none [&_input:focus]:shadow-none"
							placeholder="Search"
							label=""
							onChange={handleSearch}
						/>
						<FontAwesomeIcon
							className="cursor-pointer text-emperor"
							icon={faTimes}
							onClick={closeSearch}
						/>
					</div>
				)
			) : (
				<>
					<div className="flex gap-2 lg:text-tundora">
						<button className="xl:hidden" onClick={showSidebar}>
							<FontAwesomeIcon icon={faBars} />
						</button>
						<h2 className="font-semibold text-lg text-white lg:text-tundora">{title}</h2>
					</div>
				</>
			)}
		</div>
	)
}

export default Header
