import { useTranslation } from 'libs'

import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import HistoryTable from 'components/HistoryTable'
import Header from 'components/Header'

const ProductHistoryPage = () => {
	const { t } = useTranslation()
	const params = useParams()

	const { data: productHistory, isLoading } = useFetchData({
		name: 'fetchProductHistory',
		endpoint: `/products/${params.id}/history/`
	})

	return (
		<>
			<Header title={t('productHistory.title')} />
			<HistoryTable results={productHistory?.data} isLoading={isLoading} />
		</>
	)
}

export default ProductHistoryPage
