import { FC, useState, useContext } from 'react'
import { useTranslation } from 'libs'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { Column } from '@table-library/react-table-library/compact'

import {
	useFetchData,
	usePagination,
	useModal,
	useAxiosPrivate,
	useToast,
	useFetchInfinite,
	useFilter,
	useDevice,
	useTableSelect
} from 'hooks'
import Booth from 'components/Booth'
import EmptyTable from 'components/Ui/Table/Empty'
import DeleteConfirmModal from 'components/Modals/deleteConfirm'
import Filters from 'components/Filters'
import { IActionFilter } from 'interfaces/filters.interface'
import ConfirmModal from 'components/Modals/confirm'
import FormatModal from 'components/Modals/format'
import { IOption } from 'interfaces/select.interface'
import { defaultConfig } from 'config/default'
import { downloadAs } from 'utils/downloadFile'
import CtaLink from 'components/Ui/CtaLink'
import { customTableStyles } from 'styles/general'
import ContextMenu from 'components/Ui/ContextMenu'
import { AuthContext } from 'store/AuthContext'
import Table2 from 'components/Ui/Table2'
import Header from 'components/Header'
import Select from 'components/Ui/Select'
import FiltersWrapper from 'components/FiltersWrapper'
import Table from 'components/Ui/Table'
import Status from 'components/Ui/Status'

const Booths: FC = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { handlePagination } = usePagination(setQuery)
	const axiosPrivate = useAxiosPrivate()
	const [idToBeRemoves, setIdToBeRemoved] = useState<number | null>(null)
	const { handleFilter } = useFilter(setQuery)
	const [checkedBooths, setCheckedBooths] = useState<number[]>([])
	const queryClient = useQueryClient()
	const [chosenExportFormat, setChosenExportFormat] = useState<number | string | null>(null)
	const { isMobileOrTablet } = useDevice()
	const [boothsData, setBoothsData] = useState({ nodes: [] })
	const navigate = useNavigate()
	const { select } = useTableSelect(boothsData, onSelectChange)
	const { auth } = useContext(AuthContext)

	const {
		isOpen: isDeleteModalOpen,
		openModal: openDeleteModal,
		closeModal: closeDeleteModal
	} = useModal()
	const {
		isOpen: isBulkDeleteModalOpen,
		openModal: openBulkDeleteModal,
		closeModal: closeBulkDeleteModal
	} = useModal()
	const {
		isOpen: isExportModalOpen,
		openModal: openExportModal,
		closeModal: closeExportModal
	} = useModal()
	const {
		isOpen: isFormatModalOpen,
		openModal: openFormatModal,
		closeModal: closeFormatModal
	} = useModal()

	const successMessage = useToast({ type: 'success', message: t('booths.modal.delete.success') })
	const failMessage = useToast({ type: 'error', message: t('booths.modal.delete.error') })

	const bulkDeleteSuccessMsg = useToast({
		type: 'success',
		message: t('booths.modal.bulkDelete.success')
	})
	const bulkDeleteFailMsg = useToast({
		type: 'error',
		message: t('booths.modal.bulkDelete.error')
	})

	const exportSuccessMsg = useToast({
		type: 'success',
		message: t('booths.modal.export.success')
	})
	const exportFailMsg = useToast({
		type: 'error',
		message: t('booths.modal.export.error')
	})

	const { data: booths, isFetching: boothsLoading } = useFetchData({
		name: 'fetchBooths',
		query,
		endpoint: '/booths/',
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setBoothsData({ nodes: results })
			},
			enabled: !!auth.accessToken
		}
	})

	const {
		data: boothTypes,
		isLoading: loadingBoothTypes,
		fetchNextPage
	} = useFetchInfinite({
		name: 'fetchBoothTypes',
		endpoint: '/booth-types/',
		options: {
			enabled: !!auth.accessToken
		}
	})

	const { mutate: boothDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.delete(`/booths/${idToBeRemoves}/`)
		},
		{
			onSuccess: () => {
				successMessage()
				queryClient.invalidateQueries('fetchBooths')
			},
			onError: () => {
				failMessage()
			},
			onSettled: () => {
				closeDeleteModal()
			}
		}
	)

	const handleDelete = (id: number) => {
		setIdToBeRemoved(id)
		openDeleteModal()
	}

	const { mutate: boothsDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.post('/booths/bulk_delete/', { ids: checkedBooths })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchBooths')
				bulkDeleteSuccessMsg()
			},
			onError: () => {
				bulkDeleteFailMsg()
			},
			onSettled: () => {
				setCheckedBooths([])
				closeBulkDeleteModal()
			}
		}
	)

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value

		switch (action) {
			case 'export':
				openExportModal()
				break
			case 'delete':
				openBulkDeleteModal()
				break
		}
	}

	const deleteBulkBooths = () => boothsDeleteMutation()

	const handleExportModal = () => {
		closeExportModal()
		openFormatModal()
	}

	const handleFormatChange = (data: IOption) => {
		data?.value?.toString() ? setChosenExportFormat(data.value) : setChosenExportFormat(null)
	}

	const { mutate: exportMutation, isLoading: isExportLoading } = useMutation(
		() => {
			return axiosPrivate.post('/accounts/export/', { format: chosenExportFormat })
		},
		{
			onSuccess: (res) => {
				const format = defaultConfig.exportFormatOptions
					.find((format) => format.value === chosenExportFormat)
					?.label.toLowerCase()

				if (format) {
					downloadAs({ file: res.data, name: 'booths-export', format })
				}

				setCheckedBooths([])
				exportSuccessMsg()
			},
			onError: () => {
				exportFailMsg()
			},
			onSettled: () => {
				closeFormatModal()
				setChosenExportFormat(null)
			}
		}
	)

	const tableHeaders = [
		{
			id: 'type',
			label: t('booths.tableHeaders.type')
		},
		{
			id: 'number',
			label: t('booths.tableHeaders.number')
		}
	]

	const actionFilters = [
		{
			id: 'actions',
			label: t('filters.actions.label'),
			placeholder: t('filters.actions.placeholder'),
			options: [
				{
					value: 'export',
					label: t('booths.filters.actions.options.export')
				},
				{
					value: 'delete',
					label: t('booths.filters.actions.options.delete')
				}
			]
		}
	]

	const handleEdit = (id: number) => {
		navigate(`/booths/${id}`)
	}

	const handleDeleteModalOpen = (id: number) => {
		handleDelete(id)
	}

	const contextMenuOptions = [
		{
			label: t('booth.ellipsisMenu.edit'),
			action: handleEdit
		},
		{
			label: t('booth.ellipsisMenu.delete'),
			action: handleDeleteModalOpen
		}
	]

	const columns: Column[] = [
		{
			label: t('booths.tableHeaders.type'),
			renderCell: (item: any) => (
				<NavLink className="text-blueRibbon" to={`/booths/${item.id}`}>
					{item.booth_type_name}
				</NavLink>
			),
			select: true,
			pinLeft: true
		},
		{
			label: t('booths.tableHeaders.number'),
			renderCell: (item: any) => item.number
		},
		{
			label: t('booths.tableHeaders.isActive'),
			renderCell: (item: any) => <Status active={item.is_active} />
		},
		{
			label: '',
			renderCell: (item: any) => <ContextMenu dataId={item.id} options={contextMenuOptions} />
		}
	]

	function onSelectChange(action: any, state: any) {
		setCheckedBooths(state.ids)
	}

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
		${customTableStyles.Table};
		--data-table-library_grid-template-columns:  40px repeat(3, 1fr) 40px;
	`
	}

	return (
		<>
			<div className="flex justify-between w-full">
				<Header className="w-full" title={t('booths.title')} />
				<div className="hidden lg:w-auto lg:p-0 lg:ml-auto lg:block">
					<CtaLink to="/booths/add" text={t('booths.cta')} />
				</div>
			</div>
			<FiltersWrapper className="justify-end py-3 px-6 lg:px-0 lg:justify-between">
				<div className="flex gap-5">
					<Select
						className="hidden lg:flex"
						isLoading={loadingBoothTypes}
						key="boothType"
						id="boothType"
						placeholder={t('filters.placeholder')}
						label={t('filters.boothType')}
						options={
							!loadingBoothTypes && boothTypes != null && Array.isArray(boothTypes?.pages)
								? boothTypes?.pages
										.reduce((total: any, page: any) => {
											return [...total, ...page.results]
										}, [])
										.map((boothType: any) => {
											return { label: `${boothType.name}`, value: boothType.id }
										})
								: []
						}
						handleInfiniteScroll={fetchNextPage}
						onChange={(data: any) => handleFilter({ id: 'booth_type', value: data?.value || null })}
					/>
					<Filters
						className="hidden lg:flex"
						filters={actionFilters}
						disableActions={!checkedBooths.length}
						handleFilter={handleActionFilter}
					/>
				</div>
				<div className="w-full md:w-auto md:p-0 md:ml-auto lg:hidden">
					<CtaLink to="/booths/add" text={t('booths.cta')} />
				</div>
			</FiltersWrapper>
			{boothsData.nodes != null && Array.isArray(boothsData.nodes) && boothsData.nodes.length ? (
				<>
					{isMobileOrTablet ? (
						<Table
							isLoading={boothsLoading}
							prev={booths?.data?.previous}
							next={booths?.data?.next}
							headers={tableHeaders}
							onClick={handlePagination}>
							{booths?.data?.results.map((booth: any) => (
								<Booth key={booth.id} {...booth} openDeleteModal={handleDelete} />
							))}
						</Table>
					) : (
						<Table2
							className="h-[65vh] md:h-[calc(100vh-59px-164px)] lg:h-[calc(100vh-2rem-27px-90px-125px)] 2xl:h-[calc(100vh-2rem-27px-215px)]"
							columns={columns}
							data={boothsData}
							isLoading={boothsLoading}
							select={select}
							prev={booths?.data?.previous}
							next={booths?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}
					{isDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('booths.modal.delete.title')}
							isOpened={isDeleteModalOpen}
							closeModal={closeDeleteModal}
							confirm={boothDeleteMutation}>
							<p>{t('booths.modal.delete.text')}</p>
						</DeleteConfirmModal>
					)}
					{isBulkDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('booths.modal.bulkDelete.title')}
							isOpened={isBulkDeleteModalOpen}
							closeModal={closeBulkDeleteModal}
							confirm={deleteBulkBooths}>
							<p>{t('booths.modal.bulkDelete.text', { count: checkedBooths.length })}</p>
						</DeleteConfirmModal>
					)}
					{isExportModalOpen && (
						<ConfirmModal
							title={t('booths.modal.export.title')}
							isOpened={isExportModalOpen}
							closeModal={closeExportModal}
							confirm={handleExportModal}>
							<p>{t('booths.modal.export.text')}</p>
						</ConfirmModal>
					)}
					{isFormatModalOpen && (
						<FormatModal
							disableButton={chosenExportFormat === null}
							isOpened={isFormatModalOpen}
							closeModal={closeFormatModal}
							confirm={exportMutation}
							isLoading={isExportLoading}
							onChange={handleFormatChange}
						/>
					)}
				</>
			) : (
				<EmptyTable isLoading={boothsLoading} />
			)}
		</>
	)
}

export default Booths
