import { forwardRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import Label from 'components/Ui/Label'
import FormError from 'components/Ui/FormError'

import type { Ref } from 'react'
import type { Input as InputType } from './types'
import { cn } from 'utils/style'

const Input = forwardRef(
	(
		{
			id,
			label,
			name,
			icon,
			placeholder,
			onChange,
			disabled,
			className,
			type = 'text',
			error,
			step,
			required = false
		}: InputType,
		ref: Ref<HTMLInputElement>
	) => {
		const [showPassword, setShowPassword] = useState(false)
		const toggleShowPassword = () => {
			setShowPassword((prev) => !prev)
		}

		return (
			<div className={cn('flex flex-col gap-y-2.5', className)}>
				{label && (
					<Label htmlFor={id} required={required}>
						{label}
					</Label>
				)}
				<div className="flex items-center rounded-lg gap-2 relative">
					{icon ? (
						<>
							<div className="absolute left-0 w-10 flex justify-center items-center rounded-l-lg text-dustyGray top-0 bottom-0 text-sm bg-mercury z-10">
								{typeof icon === 'string' ? icon : <FontAwesomeIcon icon={icon} />}
							</div>
						</>
					) : null}
					<input
						type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
						id={id}
						name={name}
						placeholder={placeholder}
						onChange={onChange}
						ref={ref}
						disabled={disabled}
						step={step}
						className={cn(
							'text-xs rounded-lg w-full focus:outline-none shadow-[0_0_4px_rgba(0,0,0,0.08)] focus:shadow-[0_0_5px_rgb(70,92,255)] relative',
							icon ? 'p-2.5 pl-11' : 'p-2.5',
							type === 'password' ? 'pr-8' : 'pr-2'
						)}
					/>
					{type === 'password' ? (
						<button
							type="button"
							className="absolute right-2 text-[#808080]"
							onClick={toggleShowPassword}>
							<FontAwesomeIcon icon={faEye} />
						</button>
					) : null}
				</div>
				{error && <FormError>{error.message}</FormError>}
			</div>
		)
	}
)

export default Input
