import { ReactNode } from 'react'

import CardWrapper from './Wrapper'
import { WithClassName } from 'types/general'
import { cn } from 'utils/style'
import { IconWrapper } from 'components/IconWrapper'

type SummaryProps = WithClassName<{
	title: string
	content: string | ReactNode
	summaryCounter?: string | ReactNode
	summaryText?: string
	icon?: any
	iconBgColor?: string
	iconColor?: string
}>

const Summary = ({
	title,
	content,
	summaryCounter,
	summaryText,
	className,
	icon,
	iconBgColor = 'bg-zumthor',
	iconColor = 'text-accent'
}: SummaryProps) => {
	return (
		<CardWrapper className={cn('py-3 px-4 flex gap-4 justify-between', className)}>
			<div>
				<h3 className="text-dustyGray tracking-tighter text-sm leading-4">{title}</h3>
				<p className="text-emperor font-semibold text-3xl leading-10">{content}</p>
				<p className="text-xs text-dustyGray">
					<span className="text-malachite font-semibold">{summaryCounter}</span>{' '}
					<span>{summaryText}</span>
				</p>
			</div>
			{icon ? (
				<IconWrapper iconBgColor={iconBgColor} iconColor={iconColor}>
					{icon}
				</IconWrapper>
			) : null}
		</CardWrapper>
	)
}

export default Summary
