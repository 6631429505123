import { useTranslation } from 'libs'
import { NavLink, useNavigate } from 'react-router-dom'
import Status from 'components/Ui/Status'

import EllipsisMenu from 'components/Ui/EllipsisMenu'
import type { BoothProps } from './types'

const Booth = ({ booth_type_name, number, id, is_active, openDeleteModal }: BoothProps) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const redirectToEditPage = () => navigate(`/booths/${id}`)

	const ellipsisMenuOptions = [
		{
			label: t('booth.ellipsisMenu.edit'),
			action: redirectToEditPage
		},
		{
			label: t('booth.ellipsisMenu.delete'),
			action: () => openDeleteModal(id)
		}
	]

	return (
		<article className="relative flex flex-col text-xs font-semibold text-dustyGray p-3 border border-athensGray shadow-[0px_4px_4px_-2px_rgba(0,0,0,0.12)] bg-white rounded">
			<NavLink to={`/booths/${id}`}>
				<span className="text-doveGray">{t('booth.type')}: </span>
				{booth_type_name}
			</NavLink>
			<span>
				<span className="text-doveGray">{t('booth.number')}: </span>
				{number}
			</span>
			<span>
				<span className="text-doveGray">{t('booths.tableHeaders.isActive')}: </span>
				<Status active={is_active} />
			</span>
			<EllipsisMenu options={ellipsisMenuOptions} />
		</article>
	)
}

export default Booth
