import styled from 'styled-components'

// todo: remove PrintContainer
// right now don't know how to simulate @media screen
export const PrintContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 12px;
	// width: 38mm;
	// height: 23mm;
	width: 142px;
	height: 82px;
	page-break-before: always;

	@media screen {
		display: none;
	}
`
