// todo: export const instead of default

import { PropsWithChildren } from 'react'
import { WithClassName } from 'types/general'
import { cn } from 'utils/style'

type IFiltersWrapper = WithClassName<PropsWithChildren>

const FiltersWrapper = ({ children, className }: IFiltersWrapper) => {
	return (
		<div
			className={cn(
				'flex flex-col-reverse justify-between items-center gap-2 pb-3 md:flex-row-reverse lg:flex-row md:px-6 md:py-3 flex-nowrap lg:items-end lg:px-0 w-full',
				className
			)}>
			{children}
		</div>
	)
}

export default FiltersWrapper
