import { useContext } from 'react'

import { DashboardContext } from 'store'
import StatisticsCard from 'components/Card/Statistics'

export const DashboardBoothsList = () => {
	const dashboardCtx = useContext(DashboardContext)

	return (
		<div className="md:w-1/2">
			{dashboardCtx?.dashboard?.data?.bookings?.length || 0 > 0
				? Array.isArray(dashboardCtx?.dashboard?.data?.bookings) &&
					dashboardCtx?.dashboard?.data?.bookings.length &&
					dashboardCtx?.dashboard?.data?.bookings
						.filter((booth: any) => new Date() <= new Date(booth.end_date))
						.slice(0, 4)
						.map((booth: any, index: number) => (
							<StatisticsCard
								className="rounded-xl py-6 px-8"
								key={index}
								{...booth}
								type="range"
							/>
						))
				: ''}
		</div>
	)
}
