import { ReactNode } from 'react'
import { cn } from 'utils/style'

interface IEllipsisText {
	children: ReactNode
	textCenter?: boolean | undefined
}

export const EllipsisText = ({ children, textCenter }: IEllipsisText) => {
	return (
		<div className={cn('w-full table table-fixed', textCenter ? 'text-center' : 'text-left')}>
			<div className="overflow-hidden whitespace-nowrap text-ellipsis break-all table-cell">
				{children}
			</div>
		</div>
	)
}
