import { useTranslation } from 'libs'

import CtaLink from 'components/Ui/CtaLink'

const ErrorPage = () => {
	const { t } = useTranslation()

	return (
		<div className="flex justify-center items-center h-screen w-full px-4">
			<div className="text-center">
				<h1 className="text-tundora text-8xl font-semibold">404</h1>
				<p className="text-doveGray text-2xl">{t('404.text')}</p>
				<CtaLink className="max-w-[185px] mx-auto mt-6" to="/" text={t('404.cta')} icon={false} />
			</div>
		</div>
	)
}

export default ErrorPage
