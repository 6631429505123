import { PropsWithChildren } from 'react'
import { WithClassName } from 'types/general'
import { cn } from 'utils/style'

type ICardWrapper = WithClassName<PropsWithChildren>

// TODO: unify box-shadows
const CardWrapper = ({ children, className }: ICardWrapper) => {
	return (
		<div className={cn('bg-white shadow-[0_0_12px_rgba(0,0,0,0.08)] rounded-md', className)}>
			{children}
		</div>
	)
}

export default CardWrapper
