import { useState, useContext } from 'react'
import { useTranslation } from 'libs'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { Column } from '@table-library/react-table-library/compact'

import {
	useFetchData,
	usePagination,
	useAxiosPrivate,
	useModal,
	useToast,
	useSearch,
	useUserRoles,
	useDevice,
	useTableSelect
} from 'hooks'
import Category from 'components/Category'
import EmptyTable from 'components/Ui/Table/Empty'
import { useMutation, useQueryClient } from 'react-query'
import DeleteConfirmModal from 'components/Modals/deleteConfirm'
import Filters from 'components/Filters'
import { IActionFilter } from 'interfaces/filters.interface'
import CtaLink from 'components/Ui/CtaLink'
import { customTableStyles } from 'styles/general'
import ContextMenu from 'components/Ui/ContextMenu'
import { AuthContext } from 'store/AuthContext'
import { theme } from 'twin.macro'
import SearchInput from 'components/Ui/SearchInput'
import Table2 from 'components/Ui/Table2'
import Header from 'components/Header'
import { cn } from 'utils/style'
import FiltersWrapper from 'components/FiltersWrapper'
import Table from 'components/Ui/Table'

const CategoriesPage = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { handlePagination } = usePagination(setQuery)
	const axiosPrivate = useAxiosPrivate()
	const queryClient = useQueryClient()
	const [idToBeRemoves, setIdToBeRemoved] = useState<number | null>(null)
	const [checkedCategories, setCheckedCategories] = useState<number[]>([])
	const { isUser } = useUserRoles()
	const [categoriesData, setCategoriesData] = useState({ nodes: [] })
	const { select } = useTableSelect(categoriesData, onSelectChange)
	const { isMobileOrTablet } = useDevice()
	const navigate = useNavigate()
	const { handleSearch } = useSearch({ name: 'search', cb: setQuery })
	const { auth } = useContext(AuthContext)

	const successMessage = useToast({
		type: 'success',
		message: t('categories.modal.delete.success')
	})
	const failMessage = useToast({
		type: 'error',
		message: t('categories.modal.delete.error')
	})

	const bulkDeleteSuccessMsg = useToast({
		type: 'success',
		message: t('categories.modal.bulkDelete.success')
	})
	const bulkDeleteFailMsg = useToast({
		type: 'error',
		message: t('categories.modal.bulkDelete.error')
	})

	const { data: categories, isFetching: loadingCategories } = useFetchData({
		name: 'fetchCategories',
		endpoint: '/categories/',
		query,
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setCategoriesData({ nodes: results })
			},
			enabled: !!auth.accessToken
		}
	})

	const {
		isOpen: isDeleteModalOpen,
		openModal: openDeleteModal,
		closeModal: closeDeleteModal
	} = useModal()
	const {
		isOpen: isBulkDeleteModalOpen,
		openModal: openBulkDeleteModal,
		closeModal: closeBulkDeleteModal
	} = useModal()

	const { mutate: categoryDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.delete(`/categories/${idToBeRemoves}/`)
		},
		{
			onSuccess: () => {
				successMessage()
				queryClient.invalidateQueries('fetchCategories')
			},
			onError: () => {
				failMessage()
			},
			onSettled: () => {
				closeDeleteModal()
			}
		}
	)

	const { mutate: bulkDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.post('/categories/bulk_delete/', { ids: checkedCategories })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchCategories')
				bulkDeleteSuccessMsg()
			},
			onError: () => {
				bulkDeleteFailMsg()
			},
			onSettled: () => {
				setCheckedCategories([])
				closeBulkDeleteModal()
			}
		}
	)

	const handleDelete = (id: number) => {
		setIdToBeRemoved(id)
		openDeleteModal()
	}

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value
		switch (action) {
			case 'delete':
				openBulkDeleteModal()
				break
		}
	}

	const tableHeaders = [
		{
			id: 'category',
			label: t('categories.tableHeaders.category')
		},
		{
			id: 'productsCount',
			label: t('categories.tableHeaders.productsCount')
		}
	]

	const actionFilters = [
		{
			id: 'actions',
			label: t('categories.actions.label'),
			placeholder: t('categories.actions.placeholder'),
			options: [
				{
					value: 'delete',
					label: t('categories.actions.options.delete')
				}
			]
		}
	]

	function onSelectChange(action: any, state: any) {
		setCheckedCategories(state.ids)
	}

	const handleEdit = (id: number) => {
		navigate(`/categories/${id}`)
	}

	const handleDeleteModalOpen = (id: number) => {
		handleDelete(id)
	}

	const contextMenuOptions = [
		{
			label: t('categories.category.ellipsisMenu.edit'),
			action: handleEdit
		},
		{
			label: t('categories.category.ellipsisMenu.delete'),
			action: handleDeleteModalOpen
		}
	]

	const columns: Column[] = [
		{
			label: t('categories.tableHeaders.category'),
			renderCell: (item: any) => (
				<NavLink style={{ color: theme`colors.blueRibbon` }} to={`/categories/${item.id}`}>
					{item.name}
				</NavLink>
			),
			select: true,
			pinLeft: true
		},
		{
			label: t('categories.tableHeaders.productsCount'),
			renderCell: (item: any) => item.products_count
		},
		{
			label: '',
			renderCell: (item: any) => <ContextMenu dataId={item.id} options={contextMenuOptions} />
		}
	]

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
		${customTableStyles.Table};
		--data-table-library_grid-template-columns:  40px repeat(2, 1fr) 40px;
	`
	}

	return (
		<>
			<div className="flex justify-between w-full">
				<Header
					className={cn('w-full', isUser ? 'mb-8' : '')}
					title={t('categories.title')}
					handleSearch={handleSearch}
				/>
				<div className="hidden lg:w-auto lg:p-0 lg:ml-auto lg:block">
					<CtaLink to="/categories/add" text={t('categories.cta')} />
				</div>
			</div>
			{!isUser && (
				<FiltersWrapper className="justify-between py-3 px-6 lg:px-0">
					<div className="flex gap-5">
						<SearchInput
							className="hidden lg:flex"
							label={t('categories.filters.search.label')}
							placeholder={t('categories.filters.search.placeholder')}
							onChange={handleSearch}
						/>
						<Filters
							className="hidden lg:flex"
							filters={actionFilters}
							disableActions={!checkedCategories.length}
							handleFilter={handleActionFilter}
						/>
					</div>
					<div className="w-full md:w-auto md:p-0 md:ml-auto lg:hidden">
						<CtaLink to="/categories/add" text={t('categories.cta')} />
					</div>
				</FiltersWrapper>
			)}
			{categoriesData.nodes != null &&
			Array.isArray(categoriesData.nodes) &&
			categoriesData.nodes.length ? (
				<>
					{isMobileOrTablet ? (
						<Table
							isLoading={loadingCategories}
							prev={categories?.data?.previous}
							next={categories?.data?.next}
							headers={tableHeaders}
							onClick={handlePagination}>
							{categories?.data?.results.map((category: any) => (
								<Category key={category.id} {...category} openDeleteModal={handleDelete} />
							))}
						</Table>
					) : (
						<Table2
							className="h-[65vh] md:h-[calc(100vh-59px-164px)] lg:h-[calc(100vh-2rem-27px-90px-125px)] 2xl:h-[calc(100vh-2rem-27px-215px)]"
							columns={columns}
							data={categoriesData}
							isLoading={loadingCategories}
							select={select}
							prev={categories?.data?.previous}
							next={categories?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}
					{isDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('categories.modal.delete.title')}
							isOpened={isDeleteModalOpen}
							closeModal={closeDeleteModal}
							confirm={categoryDeleteMutation}>
							<p>{t('categories.modal.delete.text')}</p>
						</DeleteConfirmModal>
					)}
					{isBulkDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('categories.modal.bulkDelete.title')}
							isOpened={isBulkDeleteModalOpen}
							closeModal={closeBulkDeleteModal}
							confirm={bulkDeleteMutation}>
							<p>{t('categories.modal.bulkDelete.text', { count: checkedCategories.length })}</p>
						</DeleteConfirmModal>
					)}
				</>
			) : (
				<EmptyTable isLoading={loadingCategories} />
			)}
		</>
	)
}

export default CategoriesPage
