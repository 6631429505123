import Select from 'components/Ui/Select'

import type { IFilters, IFilter } from './types'

const Filters = ({ filters, disableActions, className, handleFilter }: IFilters) => {
	return (
		<div className={className}>
			<div className="flex gap-5">
				{filters.map((filter: IFilter) => (
					<Select
						key={filter.id}
						{...filter}
						isDisabled={filter.id === 'actions' && disableActions}
						onChange={(data) => handleFilter({ id: filter.id, ...data })}
					/>
				))}
			</div>
		</div>
	)
}

export default Filters
