import { useState } from 'react'
import { useTranslation } from 'libs'
import { Column } from '@table-library/react-table-library/compact'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'

import EmptyTable from 'components/Ui/Table/Empty'
import {
	usePagination,
	useFetchData,
	useSearch,
	useModal,
	useAxiosPrivate,
	useToast,
	useDevice,
	useTableSelect
} from 'hooks'
import BoothType from 'components/BoothType'
import DeleteConfirmModal from 'components/Modals/deleteConfirm'
import Filters from 'components/Filters'
import CtaLink from 'components/Ui/CtaLink'
import { IActionFilter } from 'interfaces/filters.interface'
import { customTableStyles } from 'styles/general'
import ContextMenu from 'components/Ui/ContextMenu'
import Table2 from 'components/Ui/Table2'
import Header from 'components/Header'
import SearchInput from 'components/Ui/SearchInput'
import FiltersWrapper from 'components/FiltersWrapper'
import Table from 'components/Ui/Table'

const BoothTypes = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { handlePagination } = usePagination(setQuery)
	const { handleSearch } = useSearch({ name: 'name', cb: setQuery })
	const axiosPrivate = useAxiosPrivate()
	const [checkedBoothTypes, setCheckedBoothTypes] = useState<number[]>([])
	const queryClient = useQueryClient()
	const [idToBeRemoves, setIdToBeRemoved] = useState<number | null>(null)
	const { isMobileOrTablet } = useDevice()
	const [boothTypesData, setBoothTypesData] = useState({ nodes: [] })
	const { select } = useTableSelect(boothTypesData, onSelectChange)
	const navigate = useNavigate()

	const {
		isOpen: isDeleteModalOpen,
		openModal: openDeleteModal,
		closeModal: closeDeleteModal
	} = useModal()

	const successMessage = useToast({
		type: 'success',
		message: t('boothTypes.modal.delete.success')
	})
	const failMessage = useToast({ type: 'error', message: t('boothTypes.modal.delete.error') })

	const bulkDeleteSuccessMsg = useToast({
		type: 'success',
		message: t('boothTypes.modal.bulkDelete.success')
	})
	const bulkDeleteFailMsg = useToast({
		type: 'error',
		message: t('boothTypes.modal.bulkDelete.error')
	})

	const { data: boothTypes, isFetching: loadingBoothTypes } = useFetchData({
		name: 'fetchBoothTypes',
		endpoint: '/booth-types/',
		query,
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setBoothTypesData({ nodes: results })
			}
		}
	})

	const {
		isOpen: isBulkDeleteModalOpen,
		openModal: openBulkDeleteModal,
		closeModal: closeBulkDeleteModal
	} = useModal()

	const handleDelete = (id: number) => {
		setIdToBeRemoved(id)
		openDeleteModal()
	}

	const { mutate: boothTypesDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.post('/booth-types/bulk_delete/', { ids: checkedBoothTypes })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchBoothTypes')
				bulkDeleteSuccessMsg()
			},
			onError: () => {
				bulkDeleteFailMsg()
			},
			onSettled: () => {
				setCheckedBoothTypes([])
				closeBulkDeleteModal()
			}
		}
	)

	const deleteBoothTypeMutation = useMutation(
		() => {
			return axiosPrivate.delete(`/booth-types/${idToBeRemoves}/`)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchBoothTypes')
				successMessage()
			},
			onError: () => {
				failMessage()
			},
			onSettled: () => {
				closeDeleteModal()
			}
		}
	)

	const deleteBoothType = () => deleteBoothTypeMutation.mutate()

	const deleteBulkBoothTypes = () => boothTypesDeleteMutation()

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value

		switch (action) {
			case 'delete':
				openBulkDeleteModal()
				break
		}
	}

	const tableHeaders = [
		{
			id: 'name',
			label: t('boothTypes.tableHeaders.name')
		},
		{
			id: 'description',
			label: t('boothTypes.tableHeaders.description')
		},
		{
			id: 'height',
			label: t('boothTypes.tableHeaders.height')
		},
		{
			id: 'width',
			label: t('boothTypes.tableHeaders.width')
		},
		{
			id: 'depth',
			label: t('boothTypes.tableHeaders.depth')
		}
	]

	const actionFilters = [
		{
			id: 'actions',
			label: t('filters.actions.label'),
			placeholder: t('filters.actions.placeholder'),
			options: [
				{
					value: 'delete',
					label: t('boothTypes.filters.actions.options.delete')
				}
			]
		}
	]

	const handleEdit = (id: number) => {
		navigate(`/booth-types/${id}`)
	}

	const handleDeleteModalOpen = (id: number) => {
		handleDelete(id)
	}

	const contextMenuOptions = [
		{
			label: t('boothTypes.boothType.ellipsisMenu.edit'),
			action: handleEdit
		},
		{
			label: t('boothTypes.boothType.ellipsisMenu.delete'),
			action: handleDeleteModalOpen
		}
	]

	const columns: Column[] = [
		{
			label: t('boothTypes.tableHeaders.name'),
			renderCell: (item: any) => (
				<NavLink className="text-blueRibbon" to={`/booth-types/${item.id}`}>
					{item.name}
				</NavLink>
			),
			select: true,
			pinLeft: true
		},
		{
			label: t('boothTypes.tableHeaders.description'),
			renderCell: (item: any) => item.description
		},
		{
			label: t('boothTypes.tableHeaders.height'),
			renderCell: (item: any) => `${+item.dimensions?.height} cm`
		},
		{
			label: t('boothTypes.tableHeaders.width'),
			renderCell: (item: any) => `${+item.dimensions?.width} cm`
		},
		{
			label: t('boothTypes.tableHeaders.depth'),
			renderCell: (item: any) => `${+item.dimensions?.depth} cm`
		},
		{
			label: '',
			renderCell: (item: any) => <ContextMenu dataId={item.id} options={contextMenuOptions} />
		}
	]

	function onSelectChange(action: any, state: any) {
		setCheckedBoothTypes(state.ids)
	}

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
		${customTableStyles.Table};
		--data-table-library_grid-template-columns:  40px repeat(5, 1fr) 40px;
	`
	}

	return (
		<>
			<div className="flex justify-between w-full">
				<Header className="w-full" title={t('boothTypes.title')} handleSearch={handleSearch} />
				<div className="hidden lg:w-auto lg:p-0 lg:ml-auto lg:block">
					<CtaLink to="/booth-types/add" text={t('boothTypes.cta')} />
				</div>
			</div>
			<FiltersWrapper className="justify-end py-3 px-6 lg:px-0 lg:justify-between">
				<div className="flex gap-5">
					<SearchInput
						className="hidden lg:flex"
						label={t('boothTypes.filters.search.label')}
						placeholder={t('boothTypes.filters.search.placeholder')}
						onChange={handleSearch}
					/>

					<Filters
						className="hidden lg:flex"
						filters={actionFilters}
						disableActions={!checkedBoothTypes.length}
						handleFilter={handleActionFilter}
					/>
				</div>
				<div className="w-full md:w-auto md:p-0 md:ml-auto lg:hidden">
					<CtaLink to="/booth-types/add" text={t('boothTypes.cta')} />
				</div>
			</FiltersWrapper>
			{boothTypesData.nodes != null &&
			Array.isArray(boothTypesData.nodes) &&
			boothTypesData.nodes.length ? (
				<>
					{isMobileOrTablet ? (
						<Table
							isLoading={loadingBoothTypes}
							prev={boothTypes?.data?.previous}
							next={boothTypes?.data?.next}
							headers={tableHeaders}
							onClick={handlePagination}>
							{boothTypes?.data?.results.map((boothType: any) => (
								<BoothType key={boothType.id} {...boothType} openDeleteModal={handleDelete} />
							))}
						</Table>
					) : (
						<Table2
							className="h-[65vh] md:h-[calc(100vh-59px-164px)] lg:h-[calc(100vh-2rem-27px-90px-125px)] 2xl:h-[calc(100vh-2rem-27px-215px)]"
							columns={columns}
							data={boothTypesData}
							isLoading={loadingBoothTypes}
							select={select}
							prev={boothTypes?.data?.previous}
							next={boothTypes?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}

					{isDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('boothTypes.modal.delete.title')}
							isOpened={isDeleteModalOpen}
							closeModal={closeDeleteModal}
							confirm={deleteBoothType}>
							<p>{t('boothTypes.modal.delete.text')}</p>
						</DeleteConfirmModal>
					)}
					{isBulkDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('boothTypes.modal.bulkDelete.title')}
							isOpened={isBulkDeleteModalOpen}
							closeModal={closeBulkDeleteModal}
							confirm={deleteBulkBoothTypes}>
							<p>{t('boothTypes.modal.bulkDelete.text', { count: checkedBoothTypes.length })}</p>
						</DeleteConfirmModal>
					)}
				</>
			) : (
				<EmptyTable isLoading={loadingBoothTypes} />
			)}
		</>
	)
}

export default BoothTypes
