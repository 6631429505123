import { useContext } from 'react'
import { useTranslation } from 'libs'
import { Controller, useForm } from 'react-hook-form'
// import { yupResolver } from '@hookform/resolvers/yup'
import { format, parseISO } from 'date-fns'
import { useMutation, useQueryClient } from 'react-query'

import PageWrapper from 'components/Ui/PageWrapper'
import Form from 'components/Ui/Form'
import Input from 'components/Ui/Input'
import Checkbox from 'components/Ui/Checkbox'
import DatePickerInput from 'components/Ui/Datepicker'
import { useFetchData } from 'hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { useAxiosPrivate } from 'hooks'
import { useToast } from 'hooks'
import { useUserRoles } from 'hooks'
import FormSubtitle from 'components/Ui/Form/Subtitle'
// import { openingHourValidationSchema } from 'schemas/validation'
import { HistoryButtonWrapper } from 'components/Ui/Button/History'
import { BackButton } from 'components/Ui/Button/Back'
import { AuthContext } from 'store/AuthContext'

import type { OpeningHour } from './types'
import Header from 'components/Header'

const OpeningHourPage = () => {
	const { t } = useTranslation()
	const params = useParams()
	const axiosPrivate = useAxiosPrivate()
	const queryClient = useQueryClient()
	const navigate = useNavigate()
	const { isUser } = useUserRoles()
	const { auth } = useContext(AuthContext)

	const editOpeningHourSuccessMsg = useToast({
		type: 'success',
		message: t('openingHour.form.success')
	})
	const editOpeningHourFailMsg = useToast({
		type: 'error',
		message: t('openingHour.form.error')
	})

	useFetchData({
		name: 'fetchOpeningHour',
		endpoint: `/settings/business_days/${params.id}/`,
		options: {
			onSuccess: (res: any) => {
				const { data }: { data: OpeningHour } = res

				setValue('is_open', data.is_open)
				setValue('is_bookable', data.is_bookable)
				setValue('counts_towards_duration', data.counts_towards_duration)
				setValue('reason', data.reason)
				setValue('date', parseISO(data.date))
			},
			enabled: !!auth.accessToken
		}
	})

	const { mutate: editOpeningHourMutation, isLoading: isEditMutationLoading } = useMutation(
		(data: any) => {
			return axiosPrivate.put(`/settings/business_days/${params.id}/`, data)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchOpeningHours')
				editOpeningHourSuccessMsg()
				navigate('/opening-hours')
			},
			onError: () => {
				editOpeningHourFailMsg()
			}
		}
	)

	const {
		handleSubmit,
		register,
		control,
		setValue,
		formState: { errors }
	} = useForm({
		// resolver: yupResolver(openingHourValidationSchema),
		mode: 'onChange'
	})

	const onSubmit = (data: any) => {
		const newData = {
			...data,
			date: format(data.date, 'yyyy-MM-dd')
		}
		editOpeningHourMutation(newData)
	}

	return (
		<>
			<div className="flex flex-col w-full items-start lg:flex-row lg:max-w-[461px] lg:justify-between lg:items-center">
				<Header title={t('openingHour.title')} />
				<HistoryButtonWrapper>
					<BackButton to="/opening-hours" mobile />
				</HistoryButtonWrapper>
			</div>
			<PageWrapper>
				<Form
					submitText={t('openingHour.form.submitText')}
					onSubmit={handleSubmit(onSubmit)}
					isLoading={isEditMutationLoading}
					disabled={isUser}>
					<FormSubtitle title={t('openingHour.form.title')} hasBorder={false} />

					<Controller
						control={control}
						name="date"
						render={({ field }) => (
							<DatePickerInput
								label={t('openingHour.form.date')}
								onChange={field.onChange}
								selected={field.value}
								disabled={isUser}
							/>
						)}
					/>

					<Checkbox
						{...register('is_open')}
						label={t('openingHour.form.isOpen.label')}
						desc={t('openingHour.form.isOpen.desc')}
						disabled={isUser}
					/>
					<Checkbox
						{...register('is_bookable')}
						label={t('openingHour.form.isBookable.label')}
						desc={t('openingHour.form.isBookable.desc')}
						disabled={isUser}
					/>
					<Checkbox
						{...register('counts_towards_duration')}
						label={t('openingHour.form.counts_towards_duration.label')}
						desc={t('openingHour.form.counts_towards_duration.desc')}
						disabled={isUser}
					/>
					<Input
						id="reason"
						label={t('openingHour.form.reason')}
						{...register('reason')}
						error={(errors as any).reason}
						disabled={isUser}
					/>
				</Form>
			</PageWrapper>
		</>
	)
}

export default OpeningHourPage
