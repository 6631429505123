import styled from 'styled-components'

// todo: remove iconwrapper - it's used in multiple places
export const IconWrapper = styled.div`
	position: absolute;
	left: 0;
	width: 39px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px 0 0 8px;
	color: #949494;
	top: 0;
	bottom: 0;
	font-size: 14px;
	background-color: #e3e3e3;
`
