import { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
// todo: remove twin.macro
import { theme } from 'twin.macro'
// todo: move toast into libs/toast
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import Sidebar from 'components/Sidebar'
import { DashboardContext, StoreSettingsContext, NavContext } from 'store'
import { defaultConfig } from 'config/default'
import { useAuth, useUserRoles, useFetchData } from 'hooks'

import type { DashboardRes } from 'pages/dashboard/types'
import { cn } from 'utils/style'

const Dashboard = () => {
	const navCtx = useContext(NavContext)
	const storeCtx = useContext(StoreSettingsContext)
	const dashboardCtx = useContext(DashboardContext)
	const isRefreshToken = localStorage.getItem('refresh')
	const { isUser } = useUserRoles()
	const { auth } = useAuth()

	useFetchData({
		name: 'fetchStoreSettings',
		endpoint: '/settings/',
		options: {
			onSuccess: async (res: any) => {
				await storeCtx?.setStoreSettings(res.data)

				refetchWidgetData()
			},
			enabled: !isUser
		}
	})

	const { refetch: refetchWidgetData } = useFetchData({
		name: 'fetchWidgetData',
		endpoint: '/settings/widget/',
		options: {
			onSuccess: (res: any) => {
				storeCtx?.setPrimaryColor(res.data.primary_color || theme`colors.clementine`)
			},
			enabled: false
		}
	})

	useFetchData({
		name: 'fetchDashboard',
		endpoint: '/dashboard/',
		options: {
			enabled: !!auth.accessToken,
			onSuccess: (res: DashboardRes) => {
				dashboardCtx?.setDashboard(res)
			}
		}
	})

	return isRefreshToken ? (
		<section
			className={cn(
				'flex flex-col min-h-screen bg-white xl:pl-80',
				navCtx?.isNavOpened && 'overflow-hidden h-screen'
			)}>
			<section className="flex-1 max-w-full bg-white pb-4 lg:px-9 lg:py-8">
				<Outlet />
			</section>
			<Sidebar />
			<ToastContainer
				position="bottom-center"
				autoClose={defaultConfig.redirectTimer}
				rtl={false}
				hideProgressBar
				closeOnClick={false}
			/>
		</section>
	) : (
		<Navigate to="/login" />
	)
}

export default Dashboard
