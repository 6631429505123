import { FC } from 'react'
import { useTranslation } from 'libs'

import { useParams } from 'react-router-dom'
import { useFetchData } from 'hooks'
import HistoryTable from 'components/HistoryTable'
import { StyledHeader } from './styles'

const BookingHistory: FC = () => {
	const { t } = useTranslation()
	const params = useParams()

	const { data: bookingHistory, isLoading } = useFetchData({
		name: 'fetchBookingHistory',
		endpoint: `/bookings/${params.id}/history/`
	})

	return (
		<>
			<StyledHeader title={t('bookingHistory.title')} />
			<HistoryTable results={bookingHistory?.data} isLoading={isLoading} />
		</>
	)
}

export default BookingHistory
