import styled from 'styled-components'
import { theme } from 'twin.macro'
import { useTranslation } from 'libs'
import { WithClassName } from 'types/general'

type IStatus = WithClassName<{
	status: boolean
	showText?: boolean
	activeColor?: string
	deactivatedColor?: string
}>

const Status = ({
	status,
	showText = true,
	activeColor = '#12CD0E',
	deactivatedColor = '#B07136',
	className
}: IStatus) => {
	const { t } = useTranslation()

	return (
		<StatusWrapper
			status={status}
			className={className}
			activeColor={activeColor}
			deactivatedColor={deactivatedColor}>
			<StatusIndicator
				status={status}
				activeColor={activeColor}
				deactivatedColor={deactivatedColor}
			/>
			{showText && (
				<span>{status ? t('statusIndicator.published') : t('statusIndicator.unpublished')}</span>
			)}
		</StatusWrapper>
	)
}

const StatusIndicator = styled.div<{
	status: boolean
	deactivatedColor: string
	activeColor: string
}>`
	display: none;

	@media (min-width: ${theme`screens.md`}) {
		display: block;
		width: 8px;
		height: 8px;
		background-color: ${({ status, activeColor, deactivatedColor }) =>
			status ? activeColor : deactivatedColor};
	}
`

const StatusWrapper = styled.div<{
	status: boolean
	deactivatedColor: string
	activeColor: string
}>`
	padding: 2px 4px;
	border-radius: 7px;
	grid-column-start: 2;
	// background-color: ${({ status }) => (status ? '#36B059' : theme`colors.copper`)};
	background-color: ${({ status, activeColor, deactivatedColor }) =>
		status ? activeColor : deactivatedColor};
	text-transform: uppercase;
	color: #fff;
	justify-self: end;
	font-size: 0.75rem;

	@media (min-width: ${theme`screens.md`}) {
		grid-column-start: auto;
		border-radius: 0.375rem;
		padding: 0.25rem 0.5rem;
		gap: 0.25rem;
		display: inline-flex;
		justify-content: center;
		align-items: center;
		justify-self: center;
		border: 1px solid #7c7c7c;
		background-color: transparent;
		color: #656565;
	}
`

export default Status
