import Pagination from 'components/Pagination'
import Spinner from 'components/Ui/Spinner'
import { Container, Header, Title, List, Footer } from './styles'
import { WithClassName } from 'types/general'
import { PropsWithChildren } from 'react'
import { cn } from 'utils/style'

export interface IHeader {
	id: string
	label: string
}

export type TableProps = PropsWithChildren<
	WithClassName<{
		headers: IHeader[]
		hasCheckbox?: boolean
		isLoading: boolean
		prev?: string | null
		next?: string | null
		handleCheckbox?: (e: any) => void
		onClick?: (e: any) => void
	}>
>

const Table = ({
	children,
	headers,
	className,
	hasCheckbox = false,
	isLoading,
	prev,
	next,
	handleCheckbox,
	onClick
}: TableProps) => {
	if (isLoading) return <Spinner />

	return (
		<div className={cn('py-3 px-6 lg:px-0', className)}>
			<Container>
				<Header>
					{hasCheckbox && <input type="checkbox" onChange={handleCheckbox} />}
					{headers.map((header: any) => (
						<Title key={header.id}>{header.label}</Title>
					))}
				</Header>
				<List>{children}</List>
			</Container>
			{prev || next ? (
				<Footer>
					{prev || next ? (
						<Pagination
							onClick={onClick ? onClick : () => {}}
							prev={prev ?? null}
							next={next ?? null}
						/>
					) : null}
				</Footer>
			) : null}
		</div>
	)
}

export default Table
