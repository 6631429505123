import { PropsWithChildren } from 'react'
import { WithClassName } from 'types/general'
import { cn } from 'utils/style'

type FormErrorProps = PropsWithChildren<WithClassName>

const FormError = ({ children, className }: FormErrorProps) => {
	return (
		<span className={cn('text-red text-2xs font-bold inline-block', className)}>{children}</span>
	)
}

export default FormError
