import { useTranslation } from 'libs'

import CtaLink from 'components/Ui/CtaLink'

import { WithClassName } from 'types/general'
import { cn } from 'utils/style'

export type BackButtonProps = WithClassName<{
	to: string
	mobile?: boolean
}>

export const BackButton = ({ to, className, mobile }: BackButtonProps) => {
	const { t } = useTranslation()

	return (
		<CtaLink
			to={to}
			text={t('backBtn')}
			icon={false}
			className={cn(className, mobile ? 'lg:hidden' : '')}
		/>
	)
}
