import { useState, useContext } from 'react'
import { useTranslation } from 'libs'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { Column } from '@table-library/react-table-library/compact'

import {
	useAxiosPrivate,
	useFilter,
	useFetchInfinite,
	useFetchData,
	usePagination,
	useModal,
	useToast,
	useDevice,
	useTableSelect
} from 'hooks'
import FiltersWrapper from 'components/FiltersWrapper'
import Filters from 'components/Filters'
import CtaLink from 'components/Ui/CtaLink'
import EmptyTable from 'components/Ui/Table/Empty'
import Rental from 'components/Rental'
import { IActionFilter } from 'interfaces/filters.interface'
import DeleteConfirmModal from 'components/Modals/deleteConfirm'
import MobileFilters from 'components/Filters/mobile'
import FiltersModal from 'components/Modals/filters'
import Select from 'components/Ui/Select'
import { customTableStyles } from 'styles/general'
import ContextMenu from 'components/Ui/ContextMenu'
import { Currency } from 'components/Ui'
import { AuthContext } from 'store/AuthContext'
import Table2 from 'components/Ui/Table2'
import Header from 'components/Header'
import Table from 'components/Ui/Table'
import Status from 'components/Ui/Status'

const Rentals = () => {
	const { t } = useTranslation()
	const [searchParams] = useSearchParams()
	const [checkedRentals, setCheckedRentals] = useState<number[]>([])
	const [query, setQuery] = useState<string | null>(searchParams.toString())
	const { handleFilter } = useFilter(setQuery)
	const { handlePagination } = usePagination(setQuery)
	const [idToBeRemoves, setIdToBeRemoved] = useState<number | null>(null)
	const axiosPrivate = useAxiosPrivate()
	const queryClient = useQueryClient()
	const { isMobileOrTablet } = useDevice()
	const [rentalsData, setRentalsData] = useState({ nodes: [] })
	const { select } = useTableSelect(rentalsData, onSelectChange)
	const navigate = useNavigate()
	const { auth } = useContext(AuthContext)

	const deleteSuccessMsg = useToast({
		type: 'success',
		message: t('rentals.modal.delete.success')
	})
	const deleteFailMsg = useToast({
		type: 'error',
		message: t('rentals.modal.delete.error')
	})

	const bulkDeleteSuccessMsg = useToast({
		type: 'success',
		message: t('rentals.modal.bulkDelete.success')
	})
	const bulkDeleteFailMsg = useToast({
		type: 'error',
		message: t('rentals.modal.bulkDelete.error')
	})

	const {
		isOpen: isDeleteModalOpen,
		openModal: openDeleteModal,
		closeModal: closeDeleteModal
	} = useModal()
	const {
		isOpen: isBulkDeleteModalOpen,
		openModal: openBulkDeleteModal,
		closeModal: closeBulkDeleteModal
	} = useModal()
	const {
		isOpen: isFiltersOpen,
		openModal: openFiltersModal,
		closeModal: closeFiltersModal
	} = useModal()

	const {
		data: boothTypes,
		isLoading: loadingBoothTypes,
		fetchNextPage
	} = useFetchInfinite({
		name: 'fetchBoothTypes',
		endpoint: '/booth-types/',
		options: {
			enabled: !!auth.accessToken
		}
	})

	const { data: rentals, isFetching: loadingRentals } = useFetchData({
		name: 'fetchRentals',
		query,
		endpoint: '/rentals/',
		options: {
			onSuccess: (res: any) => {
				const { results } = res.data

				setRentalsData({ nodes: results })
			},
			enabled: !!auth.accessToken
		}
	})

	const { mutate: rentalDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.delete(`/rentals/${idToBeRemoves}/`)
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchRentals')
				deleteSuccessMsg()
			},
			onError: () => {
				deleteFailMsg()
			},
			onSettled: () => {
				closeDeleteModal()
			}
		}
	)

	const { mutate: bulkDeleteMutation } = useMutation(
		() => {
			return axiosPrivate.post('/rentals/bulk_delete/', { ids: checkedRentals })
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries('fetchRentals')
				bulkDeleteSuccessMsg()
			},
			onError: () => {
				bulkDeleteFailMsg()
			},
			onSettled: () => {
				setCheckedRentals([])
				closeBulkDeleteModal()
			}
		}
	)

	const handleActionFilter = (data: IActionFilter) => {
		const action = data.value

		switch (action) {
			case 'delete':
				openBulkDeleteModal()
				break
		}
	}

	const handleDelete = (id: number) => {
		setIdToBeRemoved(id)
		openDeleteModal()
	}

	const actionFilters = [
		{
			id: 'actions',
			label: t('rentals.filters.actions.label'),
			placeholder: t('rentals.filters.actions.placeholder'),
			options: [
				{
					value: 'delete',
					label: t('rentals.filters.actions.options.delete')
				}
			]
		}
	]

	const tableHeaders = [
		{
			id: 'rental',
			label: t('rentals.tableHeaders.rental')
		},
		{
			id: 'boothType',
			label: t('rentals.tableHeaders.boothType')
		},
		{
			id: 'days',
			label: t('rentals.tableHeaders.days')
		},
		{
			id: 'commission',
			label: t('rentals.tableHeaders.commission')
		},
		{
			id: 'price',
			label: t('rentals.tableHeaders.price')
		}
	]

	const handleEdit = (id: number) => {
		navigate(`/rentals/${id}`)
	}

	const handleDeleteModalOpen = (id: number) => {
		handleDelete(id)
	}

	const contextMenuOptions = [
		{
			label: t('rentals.rental.ellipsisMenu.edit'),
			action: handleEdit
		},
		{
			label: t('rentals.rental.ellipsisMenu.delete'),
			action: handleDeleteModalOpen
		}
	]

	const columns: Column[] = [
		{
			label: t('rentals.tableHeaders.rental'),
			renderCell: (item: any) => (
				<NavLink className="text-blueRibbon" to={`/rentals/${item.id}`}>
					{item.booth_type_name?.name ? `${item.booth_type_name?.name} - ` : ''} {item.duration}
				</NavLink>
			),
			select: true,
			pinLeft: true
		},
		{
			label: t('rentals.tableHeaders.boothType'),
			renderCell: (item: any) => item.booth_type_name?.name
		},
		{
			label: t('rentals.tableHeaders.days'),
			renderCell: (item: any) => item.duration
		},
		{
			label: t('rentals.tableHeaders.commission'),
			renderCell: (item: any) => item.commission
		},
		{
			label: t('rentals.tableHeaders.price'),
			renderCell: (item: any) => <Currency price={item.price} />
		},
		{
			label: t('rentals.tableHeaders.isActive'),
			renderCell: (item: any) => <Status active={item.is_active} />
		},
		{
			label: '',
			renderCell: (item: any) => <ContextMenu dataId={item.id} options={contextMenuOptions} />
		}
	]

	function onSelectChange(action: any, state: any) {
		setCheckedRentals(state.ids)
	}

	const desktopTableStyles = {
		...customTableStyles,
		Table: `
		${customTableStyles.Table};
		--data-table-library_grid-template-columns:  40px repeat(6, 1fr) 40px;
	`
	}

	return (
		<>
			<div className="flex justify-between w-full">
				<Header className="w-full" title={t('rentals.title')} />
				<div className="hidden lg:w-auto lg:p-0 lg:ml-auto lg:block">
					<CtaLink to="/rentals/add" text={t('rentals.cta')} />
				</div>
			</div>
			<FiltersWrapper className="justify-between">
				<div className="flex gap-5">
					<Select
						className="hidden lg:flex"
						isLoading={loadingBoothTypes}
						key="boothType"
						id="boothType"
						placeholder={t('filters.placeholder')}
						label={t('filters.boothType')}
						options={
							!loadingBoothTypes && boothTypes != null && Array.isArray(boothTypes?.pages)
								? boothTypes?.pages
										.reduce((total: any, page: any) => {
											return [...total, ...page.results]
										}, [])
										.map((boothType: any) => {
											return { label: `${boothType.name}`, value: boothType.id }
										})
								: []
						}
						handleInfiniteScroll={fetchNextPage}
						onChange={(data: any) => handleFilter({ id: 'booth_type', value: data?.value || null })}
					/>
					<Filters
						className="hidden lg:flex"
						filters={actionFilters}
						disableActions={!checkedRentals.length}
						handleFilter={handleActionFilter}
					/>
				</div>
				<div className="w-full px-5 md:w-auto md:p-0 md:ml-auto lg:hidden">
					<CtaLink to="/rentals/add" text={t('rentals.cta')} />
				</div>

				<MobileFilters openFilters={openFiltersModal} />
			</FiltersWrapper>
			{rentalsData.nodes != null && Array.isArray(rentalsData.nodes) && rentalsData.nodes.length ? (
				<>
					{isMobileOrTablet ? (
						<Table
							isLoading={loadingRentals}
							headers={tableHeaders}
							prev={rentals?.data?.previous}
							next={rentals?.data?.next}
							onClick={handlePagination}>
							{rentals?.data?.results.map((rental: any) => (
								<Rental key={rental.id} {...rental} openDeleteModal={handleDelete} />
							))}
						</Table>
					) : (
						<Table2
							className="h-[65vh] md:h-[calc(100vh-59px-164px)] lg:h-[calc(100vh-2rem-27px-90px-125px)] 2xl:h-[calc(100vh-2rem-27px-215px)]"
							columns={columns}
							data={rentalsData}
							isLoading={loadingRentals}
							select={select}
							prev={rentals?.data?.previous}
							next={rentals?.data?.next}
							customStyles={desktopTableStyles}
							onClick={handlePagination}
						/>
					)}
					{isDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('rentals.modal.delete.title')}
							isOpened={isDeleteModalOpen}
							closeModal={closeDeleteModal}
							confirm={rentalDeleteMutation}>
							<p>{t('rentals.modal.delete.text')}</p>
						</DeleteConfirmModal>
					)}
					{isBulkDeleteModalOpen && (
						<DeleteConfirmModal
							title={t('rentals.modal.bulkDelete.title')}
							isOpened={isBulkDeleteModalOpen}
							closeModal={closeBulkDeleteModal}
							confirm={bulkDeleteMutation}>
							<p>{t('rentals.modal.bulkDelete.text', { count: checkedRentals.length })}</p>
						</DeleteConfirmModal>
					)}
					{isFiltersOpen && (
						<FiltersModal isOpened={isFiltersOpen} closeModal={closeFiltersModal}>
							<div className="flex flex-col gap-4">
								<Select
									isLoading={loadingBoothTypes}
									key="boothType"
									id="boothType"
									placeholder={t('filters.placeholder')}
									label={t('filters.boothType')}
									options={
										!loadingBoothTypes && boothTypes != null && Array.isArray(boothTypes?.pages)
											? boothTypes?.pages
													.reduce((total: any, page: any) => {
														return [...total, ...page.results]
													}, [])
													.map((boothType: any) => {
														return { label: `${boothType.name}`, value: boothType.id }
													})
											: []
									}
									handleInfiniteScroll={fetchNextPage}
									onChange={(data: any) =>
										handleFilter({ id: 'booth_type', value: data?.value || null })
									}
								/>
							</div>
						</FiltersModal>
					)}
				</>
			) : (
				<EmptyTable isLoading={loadingRentals} />
			)}
		</>
	)
}

export default Rentals
