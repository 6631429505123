import { useContext, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'libs'
import { NavLink } from 'react-router-dom'
// todo: move icons to libs/icons
import {
	RectangleGroupIcon,
	ShoppingBagIcon,
	Bars4Icon,
	ClipboardDocumentCheckIcon,
	BanknotesIcon,
	UsersIcon,
	EyeIcon,
	AdjustmentsHorizontalIcon,
	UserCircleIcon,
	ArrowLeftOnRectangleIcon
} from '@heroicons/react/24/outline'

import Logo from 'components/Ui/Logo'
import { AuthContext, DashboardContext, StoreSettingsContext, NavContext } from 'store'
import { useUserRoles, useLogout } from 'hooks'
import { Currency, EllipsisText } from 'components/Ui'
import { IconWrapper } from 'components/IconWrapper'
import { NavItem, DropdownItem } from './'

import type { IDropdownItem } from './types'
import LanguageSwitcher from 'components/LanguageSwitcher'
import { cn } from 'utils/style'

const Sidebar = () => {
	const { t } = useTranslation()
	const navCtx = useContext(NavContext)
	const settingsCtx = useContext(StoreSettingsContext)
	const dashboardCtx = useContext(DashboardContext)

	// todo:
	// restrict access functionality
	const { auth } = useContext(AuthContext)
	const [restrictedAccess, setRestrictedAccess] = useState(true)
	const { isUser } = useUserRoles()

	const logout = useLogout()
	const handleLogout = () => logout()

	const closeSidebar = () => {
		navCtx?.toggleNavOpened(false)
	}

	// restrict access functionality
	useEffect(() => {
		if (isUser) {
			setRestrictedAccess(true)
		} else {
			setRestrictedAccess(false)
		}
	}, [auth?.user])

	const settingsItems: IDropdownItem[] = [
		{
			to: '/store',
			label: t('sidebar.store')
		},
		{
			label: t('sidebar.settings.products')
		},
		{
			to: '/categories',
			label: t('sidebar.categories')
		},
		{
			to: '/tags',
			label: t('sidebar.tags')
		},
		{
			label: t('sidebar.settings.widget')
		},
		{
			to: '/coupons',
			label: t('sidebar.coupons')
		},
		{
			to: '/booth-types',
			label: t('sidebar.boothTypes')
		},
		{
			to: '/booths',
			label: t('sidebar.booths')
		},
		{
			to: '/floor-map',
			label: t('sidebar.floorMap')
		},
		{
			to: '/rentals',
			label: t('sidebar.rentals')
		},
		{
			to: '/opening-hours',
			label: t('sidebar.openingHours')
		}
	]

	const isSettingsDropdownActive = !!settingsItems.find(
		(item) => item.to === window.location.pathname
	)

	return (
		<aside
			className={cn(
				'border-r border-mercury hidden xl:flex fixed top-0 left-0 right-0 bottom-0 sm:w-80 flex-col p-8 bg-white justify-between overflow-auto gap-8 z-50',
				navCtx?.isNavOpened ? 'flex' : ''
			)}>
			<div>
				<button className="absolute right-4 top-4 xl:hidden" onClick={closeSidebar}>
					<FontAwesomeIcon icon={faTimes} />
				</button>
				<Logo />
				<div className="py-8">
					<NavItem
						to="/"
						text={t('sidebar.dashboard')}
						onClick={closeSidebar}
						icon={<RectangleGroupIcon />}
						end
					/>
					<NavItem
						to="/sales"
						text={t('sidebar.sales')}
						onClick={closeSidebar}
						icon={<ShoppingBagIcon />}
					/>
					<NavItem
						to="/products"
						text={t('sidebar.products')}
						onClick={closeSidebar}
						icon={<Bars4Icon />}
					/>
					<NavItem
						to="/bookings"
						text={t('sidebar.bookings')}
						onClick={closeSidebar}
						icon={<ClipboardDocumentCheckIcon />}
					/>

					<NavItem
						to="/payouts"
						text={t('sidebar.payouts')}
						onClick={closeSidebar}
						icon={<BanknotesIcon />}
					/>

					{!restrictedAccess && (
						<NavItem
							to="/users"
							text={t('sidebar.users')}
							onClick={closeSidebar}
							icon={<UsersIcon />}
						/>
					)}

					{!restrictedAccess && (
						<DropdownItem
							icon={
								<span
									className={cn(
										'block w-5 text-grayChateau bg-red-500',
										isSettingsDropdownActive && 'text-accent'
									)}>
									<AdjustmentsHorizontalIcon />
								</span>
							}
							labelText={t('sidebar.settings.title')}
							items={settingsItems}
							isActive={isSettingsDropdownActive}
						/>
					)}
				</div>
			</div>

			<div className="flex flex-col gap-2.5">
				<div className="py-2.5 px-4 flex items-center gap-4">
					<IconWrapper iconBgColor="bg-zahan" iconColor="text-apple">
						<BanknotesIcon />
					</IconWrapper>
					<div className="flex flex-col">
						<span className="text-dustyGray text-sm font-normal tracking-tight">
							{t('dashboard.cards.card1.title')}
						</span>
						<Currency
							price={{
								amount: dashboardCtx?.dashboard?.data?.balance as string,
								currency: dashboardCtx?.dashboard?.data?.currency as string
							}}
						/>
					</div>
				</div>

				<NavLink
					to="/profile"
					className="flex rounded-md py-2.5 px-4 font-semibold text-emperor text-sm items-center gap-4 [&.active]:bg-selago [&.active>*]:text-accent"
					onClick={closeSidebar}>
					<span className={cn('w-5 text-grayChateau')}>
						<UserCircleIcon />
					</span>
					<EllipsisText>
						<div>{t('sidebar.profile')}</div>
						<div style={{ fontSize: 10 }}>
							{auth?.user?.name ? (
								<>
									<span>{auth.user.name}</span>{' '}
								</>
							) : (
								<>
									<span>{auth?.user?.email}</span>{' '}
								</>
							)}
						</div>
					</EllipsisText>
				</NavLink>
				{settingsCtx?.storeSettings?.website_url && (
					<a
						href={settingsCtx?.storeSettings?.website_url}
						target="_blank"
						rel="noreferrer"
						className="flex rounded px-4 py-2.5 font-semibold items-center gap-4 text-emperor text-sm">
						<span className={cn('w-5 text-grayChateau')}>
							<EyeIcon />
						</span>
						<EllipsisText>{t('sidebar.visitStore')}</EllipsisText>
					</a>
				)}
				<LanguageSwitcher />
				<button className="flex items-center gap-2.5 py-2.5 px-4" onClick={handleLogout}>
					<span className={cn('w-5 text-grayChateau')}>
						<ArrowLeftOnRectangleIcon />
					</span>
					<span className="text-doveGray font-semibold text-sm">{t('sidebar.logout')}</span>
				</button>
				<div className="text-center text-xs mt-5 -mb-2">
					<a href="https://smartgo.is">{t('sidebar.poweredby')}</a>
				</div>
			</div>
		</aside>
	)
}

export default Sidebar
